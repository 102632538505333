import React, { useEffect, useState } from "react";
import BuyStepsTemplate from "../../components/BuyStepsTemplate";
import { ButtonFooterWrap, ContainerProducts, ContentWrap, PriceInfoContent, PriceInfoWrap, RevisionWrap, StyledButtonSecundary, StyledInputRadioCustom, TitleWrap, WhatWouldLikeToDoTitleWrap } from "./styles";
import { formatPrice } from "../../util/format";
import { useSelector } from "react-redux";
import SubHeader from "../../components/SubHeader";
import AlertInfo from "../../components/AlertInfo";
import { setTitle } from "../../services/browser";
import ProductCard from "../../components/ProductCard";
import { DeliveryType } from "../../util/deliveryType";
import ShowAcceptGetItOnDifferentDays from "../../components/ShowAcceptGetItOnDifferentDays";
import { getFriendlyDateForDeliveryTypeString } from "../../util/friendlyDate";
export default function OrderRevision({ history }) {

    const [productsList, setProductsList] = useState([]);
    const [productsWithAssociadaStock, setProductsWithAssociadaStock] = useState([]);
    const [productsWithoutAssociadaStock, setProductsWithoutAssociadaStock] = useState([]);
    const [showRadioButton, setShowRadioButton] = useState(false);
    const [amount, setAmount] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [timeContigency, setTimeContigency] = useState(0);
    const [deliveryType, setDeliveryType] = useState('');
    const [shippingValue, setShippingValue] = useState('');
    const [receiveInDifferentsDays, setReceiveInDifferentsDays] = useState(undefined);
    const [timeGetProducts, setTimeGetProducts] = useState('');
    const [showModal, setShowModal] = useState(false);

    const { cart } = useSelector(state => ({
        cart: state.cart
    }));

    useEffect(() => {
        setTitle("Revisão");
    }, []);

    useEffect(() => {
        if (cart) {
            const infoCheckout = loadInfoCheckout();
            loadInfoStore(infoCheckout);
            loadProductsFromInfoCheckout(cart.data, infoCheckout);
            loadFinalPrices(cart);
        }
    }, [cart]);

    useEffect(() => {
        let productsWithStock = [];
        let productsWithoutStock = [];
        if (productsList.length > 0) {
            const thereAreProductsInStockAssociadaAndGam = productsList.some(p => p.quantidadeAssociada > 0) && productsList.some(p => p.quantidadeGam > 0);
            setShowRadioButton(thereAreProductsInStockAssociadaAndGam);

            productsList.forEach(product => {
                let finalGamQuantity = product.quantidadeGam;
                let finalAssociadaQuantity = product.quantidadeAssociada;
                if (thereAreProductsInStockAssociadaAndGam && receiveInDifferentsDays !== undefined && !receiveInDifferentsDays) {
                    finalGamQuantity += product.quantidadeAssociada;
                    finalAssociadaQuantity = 0;
                }

                if (finalAssociadaQuantity > 0) {
                    productsWithStock.push({ ...product, quantidade: finalAssociadaQuantity });
                }
                if (finalGamQuantity > 0) {
                    productsWithoutStock.push({ ...product, quantidade: finalGamQuantity });
                }
            });
        }
        setProductsWithAssociadaStock(productsWithStock);
        setProductsWithoutAssociadaStock(productsWithoutStock);
    }, [productsList, receiveInDifferentsDays]);


    const closeModal = () => {
        setShowModal(false);
    };

    const loadInfoCheckout = () => {
        const infoCheckout = localStorage.getItem("@ConexaoGAM:checkout");
        if (infoCheckout) {
            const { dadosEntrega } = JSON.parse(infoCheckout);
            return dadosEntrega;
        }
    };

    const loadInfoStore = (infoCheckout) => {
        if (infoCheckout) {
            setDeliveryType(infoCheckout.tipoEntrega);
            setTimeContigency(infoCheckout.lojaSelecionada.tempo_contingencia_gam);
            setShippingValue(infoCheckout.lojaSelecionada.custo_entrega);
            const nextTimeStoreOpened = getNextTimeStoreOpened(infoCheckout);
            setTimeGetProducts(getFriendlyDateForDeliveryTypeString(nextTimeStoreOpened, infoCheckout.tipoEntrega, infoCheckout.lojaSelecionada.lojaHorario.tempo_para_retirada));
        }
    }

    const getNextTimeStoreOpened = (infoCheckout) => {
        if (infoCheckout) {
            const initTime = infoCheckout.lojaSelecionada.lojaHorario?.hora_inicio;
            const endTime = infoCheckout.lojaSelecionada.lojaHorario?.hora_fim;
            const storeOpeningDate = infoCheckout.lojaSelecionada.lojaHorario?.data_disponibilidade;
            return `${storeOpeningDate} ${initTime.split(':').slice(0, 2).join(':')}-${endTime.split(':').slice(0, 2).join(':')}`;
        }
    }

    const loadProductsFromInfoCheckout = (products, infoCheckout) => {
        if (products && products.length > 0) {
            let treatedProducts = [];

            const nextTimeStoreOpened = getNextTimeStoreOpened(infoCheckout);
            let [date, timeRange] = nextTimeStoreOpened.split(' ');
            let [, endTime] = timeRange.split('-');
            const endTimeBeforeClose = new Date(`${date} ${endTime}`);
            const maxWaitingDate = new Date();
            maxWaitingDate.setHours(maxWaitingDate.getHours() + infoCheckout.lojaSelecionada.tempo_contingencia_gam);
            const canWaitStore = maxWaitingDate <= endTimeBeforeClose;

            products.forEach(product => {
                if (product.estoques && product.estoques.length > 0) {
                    const stock = product.estoques.find(e => e.loja_id = infoCheckout.lojaSelecionada.id)
                    var quantityNeededInStoreStock = stock.quantidade_associada > 0 ? (product.quantidade > stock.quantidade_associada ? stock.quantidade_associada : product.quantidade) : 0;
                    const quantityMissing = product.quantidade - quantityNeededInStoreStock;
                    const gamStock = stock.quantidade - stock.quantidade_associada;
                    var quantityNeededInGamStock = gamStock > 0 ? (quantityMissing > gamStock ? gamStock : quantityMissing) : 0;

                    //predict whether the product will be in stock by the next store opening
                    if (canWaitStore) {
                        quantityNeededInStoreStock += quantityNeededInGamStock;
                        quantityNeededInGamStock = 0;
                    }

                    treatedProducts.push({ ...product, quantidadeAssociada: quantityNeededInStoreStock, quantidadeGam: quantityNeededInGamStock });
                }
            });

            if (treatedProducts.length > 0) {
                setProductsList(treatedProducts);
            }
        }
    }

    const loadFinalPrices = (cart) => {
        setAmount(cart.amount);
        const subtotal = cart?.data?.reduce(
            (acc, product) =>
                acc +
                parseFloat(
                    product?.preco_mostrar_cesta?.preco_de || product?.preco_mostrar_cesta
                ) *
                product.quantidade,
            0
        );
        setSubtotal(subtotal);
        setDiscount(subtotal - cart.amount);
    }

    const handleReveived = (e) => {
        setReceiveInDifferentsDays(e.target.value == "S");
    }

    const keepBuying = () => {
        history.push("/");
    }

    const continueToPayment = () => {
        const showModal = productsWithAssociadaStock.length > 0 && productsWithoutAssociadaStock.length > 0 && receiveInDifferentsDays;
        if (showModal)
            setShowModal(true);
        else
            confirmContinueToPayment(receiveInDifferentsDays !== undefined ? receiveInDifferentsDays : undefined);
    }

    const confirmContinueToPayment = (receiveInDifferentsDays) => {
        const infoCheckout = localStorage.getItem("@ConexaoGAM:checkout");
        const checkout = JSON.parse(infoCheckout);
        checkout.dadosEntrega.receberEmDiasDiferentes = receiveInDifferentsDays;
        localStorage.setItem("@ConexaoGAM:checkout", JSON.stringify(checkout));
        history.push("/checkout/pagamento")
    }

    return (
        <BuyStepsTemplate step={3} history={history}>
            <SubHeader title="Revisão do pedido" />
            <ShowAcceptGetItOnDifferentDays showModal={showModal} handleCloseModal={closeModal} handleContinue={() => { confirmContinueToPayment(true) }} />
            <ContentWrap>
                <RevisionWrap>
                    {showRadioButton && (
                        <>
                            <AlertInfo title="Confira os produtos e quantidades antes de finalizar a compra." text={`Alguns produtos da sua cesta estão com datas diferentes para ${deliveryType == DeliveryType.RECEBER_EM_CASA ? 'entrega' : 'retirada'}.`} />

                            <WhatWouldLikeToDoTitleWrap>O que você deseja fazer?</WhatWouldLikeToDoTitleWrap>
                            <StyledInputRadioCustom name="receiveInDifferentsDays" handleChange={handleReveived} options={[{ value: "S", label: `Quero ${deliveryType == DeliveryType.RECEBER_EM_CASA ? 'receber' : 'retirar'} os produtos em datas diferentes` }, { value: "N", label: `Quero ${deliveryType == DeliveryType.RECEBER_EM_CASA ? 'receber' : 'retirar'} todos os produtos em ${timeContigency}h` }]} rowDirection={false} />
                        </>
                    )}

                    <ContainerProducts>
                        {productsWithAssociadaStock.length > 0 && (
                            <div>
                                <TitleWrap>Produtos para {deliveryType == DeliveryType.RECEBER_EM_CASA ? 'entrega' : 'retirada'} {timeGetProducts}</TitleWrap>
                                {
                                    productsWithAssociadaStock.map((product, index) => (
                                        <ProductCard key={index} index={index} product={product} allowHandleQuantity={false} />
                                    ))
                                }
                            </div>
                        )}
                        {productsWithoutAssociadaStock.length > 0 && (
                            <div>
                                <TitleWrap>Produtos para {deliveryType == DeliveryType.RECEBER_EM_CASA ? 'entrega' : 'retirada'} em até {timeContigency}h</TitleWrap>
                                {
                                    productsWithoutAssociadaStock.map((product, index) => (
                                        <ProductCard key={index} index={index} product={product} allowHandleQuantity={false} />
                                    ))
                                }
                            </div>
                        )}
                    </ContainerProducts>
                </RevisionWrap>
                <PriceInfoWrap>
                    <TitleWrap>Resumo do Pedido</TitleWrap>
                    <PriceInfoContent>
                        <span className="price-description">Subtotal ({productsWithAssociadaStock.length + productsWithoutAssociadaStock.length} itens)</span>
                        <span className="price-value">{formatPrice(subtotal)}</span>
                    </PriceInfoContent>

                    {discount > 0 && (
                        <PriceInfoContent>
                            <span className="price-description">Descontos</span>
                            <span className="price-value">{formatPrice(discount)}</span>
                        </PriceInfoContent>
                    )}

                    {(deliveryType === DeliveryType.RECEBER_EM_CASA) && (
                        <PriceInfoContent>
                            <span className="price-description">Taxa de entrega</span>
                            <span className="price-value">{formatPrice(shippingValue)}</span>
                        </PriceInfoContent>
                    )}  

                    <PriceInfoContent className="total">
                        <span className="price-description total">
                            Total
                        </span>                        
                        <span className="price-value total">{formatPrice(amount + (deliveryType === DeliveryType.RECEBER_EM_CASA ? parseFloat(shippingValue) : 0))}</span>
                    </PriceInfoContent>

                    <ButtonFooterWrap
                        disabled={productsWithAssociadaStock.length > 0 && productsWithoutAssociadaStock.length > 0 ? receiveInDifferentsDays === undefined : false}
                        id="button-finalizar-pagamento"
                        form="payment-form"
                        type="submit"
                        onClick={continueToPayment}>
                        Avançar
                    </ButtonFooterWrap>
                    <StyledButtonSecundary text={"Continuar comprando"} onClick={keepBuying} />

                </PriceInfoWrap>
            </ContentWrap>
        </BuyStepsTemplate>
    );
}