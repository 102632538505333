import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 355px);
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 20px;
  margin-bottom: 64px;
  flex-shrink: 1;
  padding: 16px;

    @keyframes delivery {
    0% {
      left: -250px;
      top: 0px;
    }
    70% {
      left: 10px;
      top: 0px;
    }
    100% {
      left: 0;
      top: 0px;
    }
  }

  .header {
    display: flex;
    max-width: 460px;
    width: 100%;

    .title {
      width: 100%;
      font-weight: 700;
      font-size: 25px;
      color: ${props => props.theme.colors.black};
    }

    .title-exp {
      width: 100%;
      font-weight: 700;
      font-size: 25px;
      color: ${props => props.theme.colors.error};
    }
  }

  .footer {
    display: flex;
    max-width: 460px;
    width: 100%;
    margin-top:20px;

    .title {
      width: 100%;
      font-weight: 700;
      font-size: 20px;
      color: ${props => props.theme.colors.black};
      justify-content:left;
    }
  }

  .span-link {
    color:#143C97;
    font-size: 16px;
  }

  .span-copiar {
     color:#143C97;
     font-size: 14px;
     font-style: normal;
     font-weight: 700;
    }  

    .span-codigoPix {
      color: #151922;
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      width: 300px;
    }

    .span-codigoPix-hidden {
       display: none;
    }

  .container-instructions {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 0px;
    padding-top:10px;

    > span {
      max-width: 460px;
    width: 100%;
    padding-top: 5px;
    color: ${props => props.theme.colors.neutral900};
    }
  }
 
  .actions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 64px;
  }

  >.successText {
    max-width: 460px;
    width: 100%;
    padding-top: 30px;
    font-size: 16px;
    color: ${props => props.theme.colors.neutral900};
    white-space: nowrap;
  }

  .div-copyClip {
    flex: 1;
    flex-direction: row;
    gap: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

export const KeepBuying = styled(Link)`
  display: flex;
  font-size: 16px;
  padding: 15px 25px;
  width: 200px;
  max-width: 460px;
  justify-content: center;
  text-decoration: none;
  margin-right: 20px;
  color: ${props => (props.havebg === "true" ? "#ffffff" : props.theme.colors.primary)};
  align-items: center;
  border: 1px solid
    ${props => (props.havebg === "true" ? "#ffffff" : props.theme.colors.primary)};
  font-family: 'Red Hat Display', sans-serif;;
  border-radius: 4px;
  transition: 0.2s;
  font-weight: 500;
  background-color: ${props =>
    props.havebg === "true" ? props.theme.colors.primary : "transparent"};
  cursor: pointer;
  border-radius: 40px;
  span {
    display: flex;
    align-self: center;
  } 
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 12px;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const ImagePix = styled.img`
  filter: ${({ blurPix }) => (blurPix ? 'blur(5px)' : 'none')};
`;