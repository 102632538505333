import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import imgSetaAvancar from '../../assets/images/seta-avancar.svg';
import imgSetaVoltar from '../../assets/images/seta-voltar.svg';
import { Creators as BannersActions } from "../../store/ducks/banners";
import "./styles.css";

import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { defaultBanners } from "./default";
import { ContainerBanner, ContainerSpinner } from "./styles";

export default function Banner(props) {
  const dispatch = useDispatch();
  const [size, setSize] = useState("414x380");
  const [loaderSize, setLoaderSize] = useState(25);
  const { data: banners, loading, error } = useSelector(state => state.banners);
  const history = useHistory();
  const [currentBanner, setCurrentBanner] = useState(0);
  const [bannerCount, setBannerCount] = useState(0);

  const handleNextBanner = () => {
    if (currentBanner < bannerCount - 1)
      handleBanner(currentBanner + 1);
  }

  const handleBackBanner = () => {
    if (currentBanner > 0)
      handleBanner(currentBanner - 1);
  }

  const handleBanner = (position) => {
    setCurrentBanner(position);
  };

  useEffect(() => {
    if (banners[size]) {
      setBannerCount(banners[size].length)
    }
  }, [size, banners]);

  useEffect(() => {
    dispatch(BannersActions.getBannersRequest());

    function bannersSize() {
      if (window.innerWidth >= 1440) {
        setSize("1440x426");
        setLoaderSize(40);
      } else if (window.innerWidth >= 1024 && window.innerWidth <= 1440) {
        setSize("1024x348");
        setLoaderSize(35);
      }
      else if (window.innerWidth >= 736 && window.innerWidth <= 1024) {
        setSize("1024x348");
        setLoaderSize(35);
      } else if (window.innerWidth >= 414 && window.innerWidth <= 736) {
        setSize("736x380");
        setLoaderSize(35);
      } else if (window.innerWidth >= 320 && window.innerWidth <= 414) {
        setSize("414x380");
        setLoaderSize(25);
      }
    }

    window.addEventListener("resize", bannersSize);
    bannersSize();

    return () => {
      window.removeEventListener("resize", bannersSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !error) {
    return (
      <ContainerSpinner>
        <Loader
          type="Oval"
          color="#00786A"
          height={loaderSize}
          width={loaderSize}
        />
      </ContainerSpinner>
    );
  }

  if (error !== null) {
    return (
      <ContainerBanner>
        <Carousel
          selectedItem={currentBanner}
          showStatus={false}
          showThumbs={false}
          swipeable={false}
          showArrows={false}
          infiniteLoop={false}
          showIndicators={false}
        >
          {defaultBanners &&
            defaultBanners[size].map((banner, key) => (
              <Link key={key} to={banner.link}>
                <div>
                  <img className="image-banner" src={banner.caminho_imagem} alt={banner.link} title={banner.link} />
                </div>
              </Link>
            ))}
        </Carousel>
        {defaultBanners[size] && defaultBanners[size].length > 1 && (
          <div className='actions'>
            <ul>
              <li onClick={handleBackBanner}>
                <img src={imgSetaVoltar} alt="Voltar" />
              </li>
              {
                Array.from(Array(defaultBanners[size].length).keys()).map((item, index) => (
                  <li key={index} onClick={() => handleBanner(index)} >
                    <span key={index} className={currentBanner == index ? "active" : ""}></span>
                  </li>
                ))
              }
              <li onClick={handleNextBanner}>
                <img src={imgSetaAvancar} alt="Avançar" />
              </li>
            </ul>
          </div>
        )}
      </ContainerBanner>
    );
  }

  function getBannerProductsRequest(banner) {
    if (banner.tipo_link === "SEM_LINK") return;

    return history.push(`/produtos/banner/${banner.uuid}`)
  }

  return (
    <ContainerBanner>
      <Carousel
        selectedItem={currentBanner}
        showStatus={false}
        showThumbs={false}
        swipeable={false}
        showArrows={false}
        infiniteLoop={false}
      >
        {banners[size] &&
          banners[size].map((banner, key) => (
            (banner.tipo_link === "LINK" || banner.tipo_link === "DIRECIONAMENTO_HOME") ? (
              <a key={banner.uuid} href={banner.tipo_link === "DIRECIONAMENTO_HOME" ? "/" : banner.link} target={banner.nova_aba ? "_blank" : "_self"} >
                <div>
                  <img className="image-banner" src={banner.caminho_imagem} alt={banner.link} title={banner.link} />
                </div>
              </a>
            ) : (
              <div style={{ cursor: "pointer" }} key={banner.uuid} onClick={() => getBannerProductsRequest(banner)}>
                <img className="image-banner" src={banner.caminho_imagem} alt={banner.link} title={banner.link} />
              </div>
            )
          ))}
      </Carousel>
      <div className='actions'>
        {banners[size] && banners[size].length > 1 && (
          <ul>
            <li onClick={handleBackBanner}>
              <img src={imgSetaVoltar} alt="Voltar" />
            </li>
            {
              Array.from(Array(banners[size].length).keys()).map((item, index) => (
                <li key={index} onClick={() => handleBanner(index)} >
                  <span key={index} className={currentBanner == index ? "active" : ""}></span>
                </li>
              ))
            }
            <li onClick={handleNextBanner}>
              <img src={imgSetaAvancar} alt="Avançar" />
            </li>
          </ul>
        )}
      </div>
    </ContainerBanner>
  );
}
