import { call, put, select } from "redux-saga/effects";
import api from "../../services/api";
import history from "../../services/history";
import { Creators as CouponActions } from "../ducks/coupon";

//função de pedido chamada
export function* getCoupon({ payload }) {
    const { profile } = yield select(state => state.user);

  try {
    const { data } = yield call(api.post, `/coupons/validateCoupon`, {
        clienteUuid: profile.uuid,
        coupon: payload.data.cupom,
        shippingValue: payload.data.valorFrete,
        basket: payload.data.carrinho.map(item => item.id)
      });
      
      if (data.error !== undefined) {        
        yield put(CouponActions.getCouponFailure(data.error));
        return;
      } else {
        yield put(CouponActions.getCouponSuccess(data));
        return;
      }
  } catch (error) {
    window.scrollTo(0, 0);
    history.push("/checkout/pagamento");
    yield put(CouponActions.getCouponFailure(error));
  }
}

