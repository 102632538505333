import React from "react";
import { setTitle } from "../../../services/browser";
import { useDispatch } from "react-redux";
import { Creators as MyOrderActions } from "../../../store/ducks/myOrders";
import { format } from "date-fns";
import pt from "date-fns/locale/pt";
import { formatPrice } from "../../../util/format";
import bag from "../../../assets/images/bag.svg";
import { DeliveryType } from "../../../util/deliveryType";

export default function CardOrder({ order, history, actionBuyAgain }) {
  console.log(order);
  const dispatch = useDispatch();
  return (
    <div className="item-content">
      <div>
        <div className="header-content"
          onClick={() => {
            setTitle("Pedido #" + order.id);
            dispatch(MyOrderActions.setOrderSelected(order));
            if (order.pagamento.tipo_pagamento === 'O' && order.pagamento.forma_pagamento === 'PI' && (order.status === 'AP' || order.status === 'FP'))
              history.push("/checkout/pagamento/pendente");
            else
              history.push("/painel/detalhes-pedido/" + order.id);
          }}>
          <div className="items-name">
            {order?.itens[0] == undefined ? "Este pedido não possui itens" : order?.itens.map((i) => i.produto.nome).join().replace(/[\w\W]*/, (i) => { return i.length > 62 ? i.substring(0, 62) + `...` : i; })}
          </div>
          <div className="item-status">
            <span className={order?.status}>{order?.status_cliente}</span>
          </div>
        </div>
        <div className="info-content">
          <div className="info-area">
            <div className="item-id">
              <span>
                Nº do pedido
              </span>
              <span>
                <strong>{order.id}</strong>
              </span>
            </div>

            <div className="item-amount">
              <span>
                Valor
              </span>
              <span>
                <strong>
                  {formatPrice(order?.pagamento.valor_total)}
                </strong>
              </span>
            </div>

            <div className="item-date">
              <span>
                Data da compra
              </span>
              <span>
                <strong>
                  {" "}
                  {format(
                    new Date(order?.data_criacao),
                    "dd/MM/yyyy",
                    {
                      locale: pt
                    }
                  )}
                </strong>
              </span>
            </div>

            {order.data_prevista && (
              <div className="item-expected-date">
                <span>Data {order.tipo_entrega === DeliveryType.RETIRAR_NA_LOJA ? 'retirada' : 'entrega'}</span>
                <span>
                  <strong>
                    {format(new Date(order.data_prevista), "dd/MM/yyyy", { locale: pt })}
                  </strong>
                </span>
              </div>
            )}

            <div className="button-area">
              <div className="item-button repeat-order">
                <button
                  onClick={actionBuyAgain}
                >
                  <img src={bag} alt="Pedido" />
                  <span>Comprar novamente</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
