/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as OrderActions } from "../../store/ducks/order";
import { Content, DateParagraph, HourParagraph, PickupTimeContainer } from './styles';
import { getFriendlyDateForDeliveryType } from '../../util/friendlyDate';
import { DeliveryType } from '../../util/deliveryType';
import ModalLayout from '../ModalLayout';

function ShowDeliverySelectedStoreModal() {
  const dispatch = useDispatch();
  const { showDeliverySelectedStore, deliveryStoreData, checkoutData, loading } = useSelector(state => state.order);
  const [expectedDate, setExpectedDate] = useState('');
  const [expectedTimeInterval, setExpectedTimeInterval] = useState('');

  function finishOrder() {
    dispatch(OrderActions.orderRequest({ ...checkoutData }, true));
  }

  function closeModal() {
    dispatch(OrderActions.hideDeliverySelectedStoreModal());
  }

  useEffect(() => {
    return () => {
      dispatch(OrderActions.hideDeliverySelectedStoreModal());
    }
  }, [dispatch])

  useEffect(() => {
    if (deliveryStoreData) {
      const timeParam = `${deliveryStoreData.lojaHorario.data_disponibilidade} ${deliveryStoreData.lojaHorario.hora_inicio}-${deliveryStoreData.lojaHorario.hora_fim}`
      const timeToStoreOpen = getFriendlyDateForDeliveryType(timeParam, DeliveryType.RECEBER_EM_CASA, checkoutData.tempo_para_retirada);
      setExpectedDate(timeToStoreOpen.friendlyDay);
      setExpectedTimeInterval(`${deliveryStoreData.lojaHorario.hora_inicio.slice(0, -3)} até ${deliveryStoreData.lojaHorario.hora_fim.slice(0, -3)}`);
    }
  }, [deliveryStoreData]);

  return (
    <ModalLayout title={"Já estamos fechados"} showModal={showDeliverySelectedStore}
      buttonLeft={{
        text: loading ? "Confirmando compra" : "Confirmar compra", action: () => {
          finishOrder();
        }
      }}
      buttonRight={{
        text: "Não, obrigado", action: () => {
          closeModal();
        }
      }}>
      <Content>
        <p>
          Mas não se preocupe! Você poderá receber seu pedido, conforme nosso próximo horário de funcionamento informado na tabela abaixo:
        </p>
        <PickupTimeContainer>
          <h3>Horários para entrega:</h3>

          {deliveryStoreData && (
            <div>
              <DateParagraph>{expectedDate}</DateParagraph>
              <HourParagraph>{expectedTimeInterval}</HourParagraph>
            </div>
          )}
        </PickupTimeContainer>
      </Content>
    </ModalLayout>
  );
}

export default ShowDeliverySelectedStoreModal;
