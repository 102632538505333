import { Container, ContainerDescription } from './styles'
import iconInfo from "../../assets/images/icon-info.svg";
import React from 'react';
const AlertInfo = ({ title, text }) => {
    return (
        <Container>
            <img src={iconInfo} alt="Icon Info" />
            <ContainerDescription>
                <h1>{title}</h1>
                <p>{text}</p>
            </ContainerDescription>
        </Container>
    );
}

export default AlertInfo;