import React from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import FavIco from "../../components/FavIco";
import linha from "../../assets/images/LineSteps.svg";
import etapa1 from "../../assets/images/Step1.svg";
import etapa2 from "../../assets/images/Step2.svg";
import etapa2Vazia from "../../assets/images/Step2Off.svg";
import etapa3 from "../../assets/images/Step3.svg";
import etapa3Vazia from "../../assets/images/Step3Off.svg";
import etapa4 from "../../assets/images/Step4.svg";
import etapa4Vazia from "../../assets/images/Step4Off.svg";
import etapa5 from "../../assets/images/Step5.svg";
import { Container, Steps, StyledLinkLogo } from './styles';

const BuySteps = ({ step, history }) => {
  const { data } = useSelector(state => state.store);

  const handleNextPage = (url) => {
    if (step <= 4)
      history.push(url);
  };

  return (
    <>
      <FavIco />
      <Container>
        <div className='container-logo-steps'>
          <ToastContainer autoClose={2500} />
          {data && data.empresa && data.empresa.url && (
            <StyledLinkLogo to="/">
              <img
                src={`${process.env.REACT_APP_API_URL}/companies/images/${data?.empresa?.url}/logos/logo.png`}
                title={data?.empresa?.nome}
                alt={`${data?.empresa?.nome}`}
              />
            </StyledLinkLogo>

          )}
          <Steps>
            <img className='line' src={linha} width={50} alt={""}></img>
            <div className='lineEvo' style={{ "width": step > 1 && step < 4 ? ((100 * (step - 1)) + 75) + "px" : (step == 4 || step == 5 ? "325px" : "75px") }} />
            <img src={etapa1} onClick={() => handleNextPage("/carrinho")} width={50} title={"Cesta de Compras"}></img>
            {step > 1 ? (
              <img src={etapa2} onClick={() => handleNextPage("/checkout/entrega")} width={50} title={"Opções de Entrega"}></img>
            ) : (
              <img src={etapa2Vazia} onClick={() => handleNextPage("/checkout/entrega")} width={50} title={"Opções de Entrega"}></img>
            )}
            <img src={step <= 2 ? etapa3Vazia : etapa3} onClick={step != 5 && step > 2 ? () => handleNextPage("/checkout/revisao") : undefined} width={50} title={"Revisão do pedido"}></img>
            {
              step == 5 ? (
                <img src={etapa5} width={50} title={"Pedido finalizado"}></img>
              ) : (
                step > 3 ? (
                  <img src={etapa4} onClick={step != 5 && step > 3 ? () => handleNextPage("/checkout/pagamento") : undefined} width={50} title={"Forma de Pagamento"}></img>
                ) : (
                  <img src={etapa4Vazia} onClick={step != 5 && step > 3 ? () => handleNextPage("/checkout/pagamento") : undefined} width={50} title={"Forma de Pagamento"}></img>
                )
              )
            }
          </Steps>
        </div>
      </Container>
    </>
  );
};

export default BuySteps;
