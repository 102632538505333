import styled from "styled-components";

export const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  padding-left: 10px;
  margin-bottom: 24px;
  h1{
    font-size: 25px;
    color: ${props => props.theme.colors.black};
  }
`;

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  width: 100%;
`;

export const NoResultsFound = styled.div`
  display: flex;
  width: 100%;
  margin-top: 85px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 355px);

  span {
    margin-top: 10px;
    color: ${(props) => props.theme.colors.primary};
    font-size: 18px;
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  b {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 22px;
  }
`;

export const FilterInMobile = styled.div`
  background: ${props => props.theme.colors.white};
  height: 100vh;
  width: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  display: block;

  div {
    width: 100%;
    #teste {
      margin-left: 12px;
    }
  }

  button {
    border: 0;
    padding: 12px;
    font-size: 12px;
    width: 80%;
    margin-left: 10%;
  }
`;

export const ContainerHeaderItemList = styled.div`
  width: 100%;
`;

export const ContainerFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${props => props.hasMarginTop ? 40 : 0}px;
  width: 100%;
  .ordenation{
    justify-content: flex-end;
    width: 286px;
    display:flex;
  }
`;

export const ContainerProductFound = styled.div`
  width: 100%;
  span{
    font-size: 14px;
    color: ${(props) => props.theme.colors.black};
  }
`;

export const ButtonFilterMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 14px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const BackgroundFlag = styled.div`
  width: 100%;
  background: ${(props) => props.theme.colors.primary};
  height: 50px;
  margin-bottom: 24px;
`;
