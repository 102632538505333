import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import emptyCart from "../../assets/images/emptyCart.svg";
import BuyStepsTemplate from "../../components/BuyStepsTemplate";
import SubHeader from "../../components/SubHeader";
import { setTitle } from "../../services/browser";
import { Creators as CartActions } from "../../store/ducks/cart";
import {
  ButtonFooterWrap,
  ContainerPageCart,
  ContentWrap,
  EmptyCartImage,
  EmptyCartMessageContainer,
  EmptyCartWrap,
  GoBackButton,
  NoRewardsProgramMessage,
  PriceInfoContent,
  PriceInfoWrap,
  StyledButtonSecundary,
  TitleWrap,
  Wrap
} from "./styles";
import "./styles.css";

import SpecialPricesModal from "../../components/SpecialPricesModal";
import { formatPrice } from "../../util/format";

import { FiX } from "react-icons/fi";
import "react-toastify/dist/ReactToastify.css";
import ProductCard from "../../components/ProductCard";

export default function Cart({ history }) {
  const showDiscount = false;

  const dispatch = useDispatch();
  const [alert, setAlert] = useState(true);
  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const signed = useSelector((state) => state.auth.signed);
  const { programa_fidelidade = true } = useSelector((state) =>
    state.user.profile == null ? true : state.user.profile
  );
  const { data } = useSelector((state) => state.store);

  const programa_fidelidade_nome = useSelector((state) =>
    state.store.data == null
      ? "Programa de Fidelidade"
      : state.store.data.empresa.programa_fidelidade_nome
  );

  const {
    showProductConditions,
    data: basket,
    amount,
  } = useSelector((state) => state.cart);

  const [frontBasket, setFrontBasket] = useState([]);

  useEffect(() => {
    setTitle("Cesta", true);
    dispatch(CartActions.getStorage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFrontBasket(basket);
  }, [basket]);

  useEffect(() => {
    const subtotal = basket?.reduce(
      (acc, product) =>
        acc +
        parseFloat(
          product?.precos[0]?.preco_de || product?.preco_mostrar_cesta
        ) *
        product.quantidade,
      0
    );
    setSubtotal(subtotal);
    setDiscount(subtotal - amount);
  }, [amount, basket]);

  if (frontBasket && frontBasket.length > 0) {
    return (
      <BuyStepsTemplate step={1} history={history}>
        <ContainerPageCart>
          <div className="produtcs-and-offers">
            <SubHeader title="Cesta de compras" />

            {signed && !programa_fidelidade && alert && (
              <NoRewardsProgramMessage>
                <span>
                  Aproveite os preços diferenciados para quem tem{" "}
                  {programa_fidelidade_nome}!{" "}
                  <Link to="/painel/programa-fidelidade">Quero participar</Link>
                </span>
                <button onClick={() => setAlert(!alert)}>
                  <FiX />
                </button>
              </NoRewardsProgramMessage>
            )}
            <ContentWrap>
              <Wrap>
                <TitleWrap>Meus itens</TitleWrap>
                {frontBasket.map((product, index) => (
                  <ProductCard key={index} index={index} product={product} allowHandleQuantity={true} />
                ))}
              </Wrap>
              <PriceInfoWrap>
                <TitleWrap>Resumo do Pedido</TitleWrap>
                <PriceInfoContent>
                  <span className="price-description">Subtotal ({frontBasket.length} itens)</span>
                  <span className="price-value">{formatPrice(subtotal)}</span>
                </PriceInfoContent>

                {discount > 0 && (
                  <PriceInfoContent>
                    <span className="price-description">Descontos</span>
                    <span className="price-value">{formatPrice(discount)}</span>
                  </PriceInfoContent>
                )}

                <PriceInfoContent className="total">
                  <span className="price-description total">
                    Total
                  </span>
                  <span className="price-value total">{formatPrice(amount)}</span>
                </PriceInfoContent>

                <ButtonFooterWrap
                  id="btn-prosseguir"
                  onClick={() => {
                    if (!signed)
                      return history.push("/entrar", {
                        previousPage: history.location.pathname,
                      });
                    history.push("/checkout/entrega");
                  }}
                >
                  Avançar
                </ButtonFooterWrap>
                <StyledButtonSecundary text={"Continuar comprando"} onClick={() => history.push("/")} />
              </PriceInfoWrap>
            </ContentWrap>

            <SpecialPricesModal visible={showProductConditions} />
          </div>
        </ContainerPageCart>
      </BuyStepsTemplate>

    );
  } else {
    return (
      <>
        <BuyStepsTemplate step={1} history={history}>
          <SubHeader title="Cesta de compras" />
          <EmptyCartWrap>
            <EmptyCartImage src={emptyCart} alt="Empty Cart" />
            <EmptyCartMessageContainer>
              <span>Sua cesta de compras está vazia no momento!</span>
              <span>Adicione produtos a ela para continuar com a compra.</span>
            </EmptyCartMessageContainer>
            <Link to="/">
              <GoBackButton text="Voltar para o início" />
            </Link>
          </EmptyCartWrap>
        </BuyStepsTemplate>
      </>
    );
  }
}
