import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import imgSetaAvancar from '../../assets/images/seta-avancar.svg';
import imgSetaVoltar from '../../assets/images/seta-voltar.svg';
import api from "../../services/api";
import { Card, Container } from './styles';

export default function CategoryFeatured({ category }) {

  const getPageSize = (divisor, productsCount) => {
    const size = Math.abs(productsCount / divisor);
    const intSize = parseInt(size);
    if (size > intSize)
      return intSize + 1;
    else
      return intSize;
  };

  const store = useSelector(state => state.storeByUser.data);

  const [indexScroll, setIndexScroll] = useState(0)
  const [pageSize, setPageSize] = useState(getPageSize(4, 10))
  const [products, setProducts] = useState([]);
  const [qtdProducs, setQtdProducs] = useState(10);

  const divProducts = React.createRef();

  const handleWindowResize = useCallback(() => {
    let length = qtdProducs;
    if ((window.innerWidth >= 589 && window.innerWidth <= 767) || (window.innerWidth >= 768 && window.innerWidth <= 1024) || window.innerWidth >= 1025) {
      setPageSize(getPageSize(4, length));
    } else if (window.innerWidth >= 481 && window.innerWidth <= 588) {
      setPageSize(getPageSize(3, length));
    } else if (window.innerWidth >= 320 && window.innerWidth <= 480) {
      setPageSize(getPageSize(2, length));
    }

    setIndexScroll(0);
  }, [qtdProducs]);


  const handleNextPage = () => {
    if (indexScroll < (pageSize - 1))
      handlePage(indexScroll + 1);
  }

  const handleBackPage = () => {
    if (indexScroll > 0)
      handlePage(indexScroll - 1);
  }

  const handlePage = (index) => {
    const left = (divProducts.current.scrollWidth / pageSize) * index;

    divProducts.current.scrollTo({
      left: left,
      behavior: 'smooth',
      duration: 9000
    });
  };

  const handleScroll = (event) => {
    const proporcionalWidth = divProducts.current.scrollWidth / pageSize;
    const index = Math.round(event.target.scrollLeft / proporcionalWidth);
    setIndexScroll(index);
  }

  const searchProductsByCategory = useCallback((idCategory, type) => {
    api.get(`products?${type === 'C' ? '_category' : '_subCategory'}=${idCategory}&_offset=${0}&_limit=${10}${store !== null ? `&store=${store.uuid}` : ""}`).then((response) => {
      setProducts(response.data);
      setQtdProducs(response.data?.length ?? 10);
    });
  }, [store]);

  useEffect(() => {
    searchProductsByCategory(category.id, category.type);

    window.addEventListener("resize", handleWindowResize);

    handleWindowResize();

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize, category.id, category.type, searchProductsByCategory]);

  useEffect(() => {
    handleWindowResize();
  }, [handleWindowResize, products]);

  return (
    <Container>
      {products?.length > 0 && category !== undefined && (
        <>
          <h2>{category.description}</h2>
          <div className='images'>
            {category.image1 && (
              <div className='primary'>
                <img src={category.image1} alt={`Imagem ${category.description}`}/>
              </div>
            )}
            {category.image2 && (
            	<div className='secondary'>
                <img src={category.image2} alt={`Imagem ${category.description} 2`} />
              </div>
            )}
          </div>
          <div className='products' ref={divProducts} onScroll={handleScroll}>
            {products &&
              products.map((product) => (
                <Card key={product.id} product={product} hasSelectedStore={store !== null} />
              ))}
          </div>
          <div className='actions'>
            <ul>
              <li onClick={handleBackPage}>
                <img src={imgSetaVoltar} alt="Voltar" />
              </li>
              {pageSize && (
                Array.from(Array(pageSize).keys()).map((item, index) => (
                  <li key={index} onClick={() => handlePage(index)} >
                    <span key={index} className={indexScroll === index ? "active" : ""}></span>
                  </li>
                ))
              )}
              <li onClick={handleNextPage}>
                <img src={imgSetaAvancar} alt="Avançar" />
              </li>
            </ul>
          </div>
        </>
      )}
    </Container>
  );
}
