import styled from 'styled-components';

export const Container = styled.div`

`;

export const Modal = styled.div`
  background: #fff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 100000;
  background: rgba(0, 0, 0, 0.7);

  > div {
    width: 500px;
    min-height: 180px;
    margin: 0 10px;
    background: #fff;
    border-radius: 15px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.07);

    .content {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      h1 {
        color: ${(props) => props.theme.colors.headingLight};
        padding: 10px 30px;
        font-size: 2.2rem;
        font-weight: 400;
        width: 100%;
      }

      p {
        color: ${(props) => props.theme.colors.neutral900};
        padding:32px;
        font-size: 1.8rem;
        font-weight: 300;
        width: 100%;
      }
    }

    .footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-height: 70px;
      border-radius: 0 0 15px 15px;
      background: #fff;
      border-top: ${(props) => props.theme.colors.primary};

      .confirm-btn {
        border: none;
        height: 40px;
        min-height: 40px;
        font-weight: 700;
        background: ${(props) => props.theme.colors.primary};        
        font-size: 16px;
        color: #fff;
        padding: 5px 10px;
        border-radius: 40px;
        margin-right: 10px;
        margin-left: 10px;
      }

      .cancel-btn {        
        border: none;
        height: 40px;
        font-weight: 700;
        background: none;
        font-size: 16px;
        color: ${(props) => props.theme.colors.primary};        
        padding: 5px 15px;        
        border-radius: 40px;
      }
    }
  }
`;
