import styled from "styled-components";

export const Container = styled.div`
  min-height: calc(100vh - 355px);
  max-width: 1410px;
  margin: 0 auto;
  .products{
    @media (max-width: 1440px){
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;
