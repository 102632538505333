import React from "react";
import { Modal } from "./styles";
import ReactHtmlParser from "react-html-parser";

function ModalConfirm({
  visible = false,
  confirm,
  cancel,
  message = "Você realmente deseja realizar esta ação?",
  textBtnConfirm = "Sim, aceito",
  textBtnCancel = "Cancelar"
}) {
  return (
    <>
      {visible && (
        <Modal>
          <div>
            <div className="content">
              <p>{ReactHtmlParser(message)}</p>
            </div>

            <div className="footer">
              <button className="cancel-btn" onClick={() => cancel()}>
                {textBtnCancel}
              </button>
              {confirm && (
                <button onClick={() => confirm()} className="confirm-btn">
                  {textBtnConfirm}
                </button>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default ModalConfirm;
