import React from "react";
import ButtonPrimary from "../ButtonPrimary";
import ButtonSecundary from "../ButtonSecundary";
import imgFechar from "../../assets/images/close-popup.svg";
import { Paragraph } from "./styles";
import ModalLayout from '../ModalLayout';

const ShowAcceptGetItOnDifferentDays = ({ showModal, handleCloseModal, handleContinue }) => {
    return (
        <ModalLayout title={"Fique atento!"} showModal={showModal}
            buttonLeft={{
                text: "Sim, continuar", action: () => {
                    handleContinue();
                }
            }}
            buttonRight={{
                text: "Não", action: () => {
                    handleCloseModal();
                }
            }}
        >
            <Paragraph>Alguns produtos da sua cesta, serão retirados em dias diferentes.</Paragraph>
            <Paragraph>Você deseja continuar com a compra?</Paragraph>
        </ModalLayout>
    );
};

export default ShowAcceptGetItOnDifferentDays;