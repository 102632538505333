import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { darken } from "polished";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Card = styled.div`
  padding: 18px;
  min-height: 130px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #cecece78;
  display: flex;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 10px;
  gap: 15px;
  align-items: center;
  .excluir-item-cesta{
    cursor: pointer;
  }

  .container-left{
    display: flex;
    flex-direction: column;
    gap: 9px;
  }

  .container-right{
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:nth-last-child(1){
      flex: 3;
    }

    &:nth-last-child(2){
      flex: 2;
    }

    &:nth-last-child(3){
      flex: 1;
    }

    .handle-quantity{
      display: flex;
      flex-direction: row;

      @media (max-width: 660px) {
        width: 100%;
        justify-content: space-between;
      }
    }

    .container-price-item{
      display: flex;
      @media (max-width: 660px) {
        width: 100%;
        justify-content: end;
      }
    }

    @media (min-width: 661px) {
      flex-direction: row;
    }
  }
`;

export const ThumbnailWrap = styled.div`
  display: flex;
  width: 84px;
  height: 97px;
  justify-content: stretch;
  align-items: stretch;
  background: #fff;
  border-radius: 8px;
  border: 1px solid var(--neutral-200, #D0D1D3);
  padding: 2px;

  img {
    min-width: 100%;
    max-width: 100px;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 660px) {
    flex: 0 0 84px;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  gap: 10px;

  @media (max-width: 660px) {
    align-items: center;
  }

  button.special-conditions {
    width: 145px;
    height: 30px;
    border: none;
    color: ${props => props.theme.colors.bodyDark};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    text-decoration: none;
    font-weight: 400;
    background: ${props => props.theme.colors.primary};
    border-radius: 3px;
    transition: background 200ms ease-in-out;

    :hover {
      background: ${props => darken(0.05, props.theme.colors.primary)};
    }
  }
`;

export const ProductTitle = styled(Link)`
  font-family: 'Red Hat Display', sans-serif;;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  overflow: hidden;
  color: ${props => props.theme.colors.headingLight};
  text-transform: capitalize;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px; /* fallback */
  max-height: 100px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

export const QuantityWrap = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  input {
    width: 40px;
    height: 30px;
    text-align: center;
    outline: none;
    font-family: 'Red Hat Display', sans-serif;;
    font-size: 16px;
    border: none;
    background: ${props => props.theme.colors.white};
  }

  button {
    fill: ${props => props.theme.colors.primary};
    background: ${props => props.theme.colors.white};
    border: solid 1px ${props => props.theme.colors.primary};
    border-radius: 25px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const RemoveIcon = styled(IoIosRemove)``;

export const AddIcon = styled(IoIosAdd)``;

export const Discount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;

  span {
    color: ${props => props.theme.colors.bodyDark};
    background: ${props => props.theme.colors.primary};
    text-align: center;
    padding: 5px 10px;
    font-size: 1.4rem;
    font-weight: 400;
    border-radius: 25px;
  }

  @media (max-width: 660px) {
    justify-content: center;
    width: 100%;
    margin-top: 10px;

    span {
      width: 145px;
      padding: 5px 0;
    }
  }
`;

export const ProductValue = styled.div`
align-self: center;
min-width: 100px;

div.fidelity-price-container {
  display: flex;
  flex-direction: column;

  p.old-price-text{
    color: ${props => props.theme.colors.neutral900};
    text-align: end;
    font-size: 12px;
    font-weight: 500;
    line-height: 18.5px;
  }

  span.old-price {
    color: ${props => props.theme.colors.neutral900};
    font-family: 'Red Hat Display', sans-serif;;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
    margin-bottom: 5px;
    text-align: right;
    text-decoration: line-through;
    line-height: 18.5px;
  }

  div.fidelity-price {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span.new-price {
      color: ${props => props.theme.colors.neutral900};
      font-family: 'Red Hat Display', sans-serif;;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26.5px;
    }

    img {
      width: 40px;
      height: 25px;
      margin-left: 10px;
    }
  }
}
`;

export const SpecialPriceContainer = styled.div`
  &.isDesktop{
      display: none;

      @media (min-width: 661px) {
        display: flex;
      }
  }
  &.isMobile{
    @media (min-width: 661px) {
        display: none;
      }
  }
  .special-prices-container {
    display: flex;
    align-items: center;
    gap: 5px;

    

    @media (max-width: 660px) {
      flex-direction: column;
    }
  }

  .biggest-discount {
    width: 100%;
    max-width: 120px;
    background-color: ${props => props.theme.colors.promotion};
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    span {
      color: ${props => props.theme.colors.white};
      font-size: 1.3rem;
      text-align: center;
    }
  }
`;

export const SpecialPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;

  span {
    color: ${props => props.theme.colors.bodyDark};
    background: ${props => props.theme.colors.secondary};
    text-align: center;
    padding: 6px 10px;
    font-size: 1.2rem;
    font-weight: 400;
    border-radius: 2px;
    text-transform: uppercase;
  }

  @media (max-width: 660px) {
    justify-content: center;
    width: 100%;

    span {
      width: 145px;
      padding: 5px 0;
    }
  }
`;