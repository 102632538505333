import axios from "axios";
import store from "../store";
import { Creators as AuthActions } from "../store/ducks/auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(async config => {
  const token = store.getState().auth.accessToken;
  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const refreshToken = store.getState().auth.refreshToken;
  if (refreshToken !== null) {
    config.headers['x-refresh-token'] = refreshToken;
  }

  return config;
});

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter(callback => callback(accessToken));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

function fetchAccessToken() {
  return api.put("/private/token", {
    refreshToken: store.getState().auth.refreshToken
  });
}

function refreshToken(tokens) {
  if (tokens !== undefined) {
    store.dispatch(
      AuthActions.refreshTokenSuccess(tokens.accessToken, tokens.refreshToken)
    );
  }
}

api.interceptors.response.use(
  function (response) {

    refreshToken(response.data.tokens);

    if (response.data.data !== undefined)
      response.data = response.data.data

    return response;
  },
  function (error) {
    const {
      config,
      response: { status, data }
    } = error;

    const originalRequest = config;

    if (status === 401) {
      if (!store.getState().auth.signed) {
        return Promise.reject(error);
      }
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;

        fetchAccessToken().then(response => {
          isAlreadyFetchingAccessToken = false;

          const { accessToken, refreshToken } = response.data;

          store.dispatch(
            AuthActions.refreshTokenSuccess(accessToken, refreshToken)
          );

          onAccessTokenFetched(accessToken);
        });
      }

      const retryOriginalRequest = new Promise(resolve => {
        addSubscriber(accessToken => {
          originalRequest.headers.Authorization = "Bearer " + accessToken;

          resolve(axios(originalRequest));
        });
      });

      return retryOriginalRequest;
    } else if (status === 400) {
      if (data) {
        refreshToken(data.tokens);
        error.response.data = data.data ? data.data : data;
      }
    }

    return Promise.reject(error);
  }
);

export default api;
