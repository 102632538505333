import React from "react";
import { ToastContainer } from "react-toastify";
import { FooterCompact } from "../Footer";
import Header from "../Header";
import ScrollToTop from "../ScrollToTop";

const MainLayoutCompact = ({ children, history }) => {
  return (
    <>
      <ScrollToTop />
      <ToastContainer autoClose={2500} />
      <Header history={history} />
      {children}
      <FooterCompact />
    </>
  );
}

export default MainLayoutCompact;
