import { darken } from "polished";
import styled from "styled-components";
import { boolean } from "yup";

export const ItemsList = styled.ul`
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding-bottom: ${props => props.collapsed ? 20 : 0}px;
  padding-top: 20px;
  border-radius: 10px;
  border: 1px solid #d3d3d3;

  h2 {
    font-size: 1.6rem;
    color: ${props => darken(0.05, props.theme.colors.success)};
    margin-bottom: 16px;
    margin-top: 16px;
    position: relative;
    margin-left: 26px;

    &::after {
      content: "";
      width: 18px;
      height: 18px;
      background: red;
      left: -26px;
      position: absolute;
      background: ${props => darken(0.05, props.theme.colors.success)};
    }
  }

  span.none-accepted {
    color: ${props => props.theme.colors.neutral900};
    font-size: 1.6rem;
    font-weight: 400;
    align-self: center;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Header = styled.div`
  padding: 0px 20px;
`;

export const Item = styled.li`
  display: flex;
  border-radius: 4px;
  align-items: center;
  width: 100%;
  cursor: pointer;
  flex-direction: column;

  + li {
    margin-top: 16px;
  }

  img {
    object-fit: contain;
    width: 53px;
    height: 53px;
    margin: 8px;
    align-self: center;
  }

  div {
    display: flex;
    flex-direction: column;
    margin: 8px;

    strong {
      color: ${props => darken(0.05, props.theme.colors.neutral900)};
      font-weight: 500;
    }
  }

  div.content-area {
    display: flex;
    flex-direction: row;
    margin: 0;
    align-self: start;
    width: 100%;
  }

  div.text-area {
    display: flex;
    flex-direction: column;
    margin: 8px;
    width: 100%;
    align-self: center;

    span.oldValue{
      font-size: 12px;
    }

    span {
      color: ${props => darken(0.05, props.theme.colors.neutral900)};
    }

    span.value {
      color: ${props => props.theme.colors.black};
    }

    span.quantity {
      font-size: 14px;
      font-weight: 500;
      color: ${props => props.theme.colors.black};
    }

    div.row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  padding: 0px 24px 24px 24px;
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h3{
    font-size: 14px;
    font-weight: 700;
  }
  p{
    font-size: 13px;
    font-weight: 500;
    font-style: italic;
  }
`;

export const ItemContainerSecondary = styled(ItemContainer)`
  padding-top: 24px;
  border-top: 1px solid #D0D1D3;
  border-radius: 8px;
  background-color: #F8F8F8;
`;

// neutral900
