import styled from "styled-components";

export const Container = styled.div`
  -webkit-touch-callout: none;  /* iPhone OS, Safari */
  -webkit-user-select: none;    /* Chrome, Safari 3 */
  -khtml-user-select: none;     /* Safari 2 */
  -moz-user-select: none;       /* Firefox */
  -ms-user-select: none;        /* IE10+ */
  user-select: none;  
  width: 220px;

  h1 {
    margin-bottom: 12px;
  }
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 44px;
  color: ${props => props.theme.colors.black};
`;

export const Header = styled.h3`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 24px;
  color: ${props => props.theme.colors.black};
`

export const ContainerCheckFilter = styled.div`
  margin-bottom: 16px;
  label {
    margin-left: 8px;
    font-size: 16px;
  }

  input {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  
`;

export const ContainerButton = styled.div`
  border-radius: 16px 16px 0px 0px;
  border-top: 1px solid ${props => props.theme.colors.gray};
  padding-bottom: 24px;

  .buttonFilter{
    margin-top: 26px;
    margin-bottom: 24px;
  }
`;

export const ContainerInfoFilter = styled.div`
  padding: 0px ${props => props.hasPadding ? 24 : 0}px;;
`;

export const ContainerFilters = styled.div`
`;

export const ContainerCloseRound = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  
  .closeRound{
    margin-top: 40px;
    margin-right: 16px;
  }
`;