import styled from 'styled-components';

export const Container = styled.div`
    display: inline-flex;
    background-color: ${({ theme }) => theme.colors.info};
    padding: 24px;
`;

export const ContainerDescription = styled.div`
    margin-left: 16px;
    p{
        font-size: 14px;
        color: ${({ theme }) => theme.colors.black};
    }
`;