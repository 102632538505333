import React, { useEffect } from "react";
import { useSelector } from "react-redux";
export default function FavIco() {

  const { data } = useSelector(state => state.store);

  useEffect(() => {
    const loadFavIcon = (linkRel) => {
      if (data?.empresa?.url) {
        let link = document.querySelector(`link[rel~='${linkRel}']`);
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = `../favicon-${data.empresa.url}.ico`;
      }
    }

    loadFavIcon("icon");
    loadFavIcon("shortcut icon");
    loadFavIcon("apple-touch-icon");
  }, [data]);

  return null;
}
