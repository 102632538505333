import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import MaskedInput from "react-text-mask";
import close from "../../assets/images/close-popup.svg";
import refresh from "../../assets/images/refresh.svg";
import Error from "../../components/Error";
import api from "../../services/api";
import { Creators as AddressActions } from "../../store/ducks/address";
import { Creators as ErrorActions } from "../../store/ducks/error";
import {
  AddressForm,
  AddressesManagement,
  ButtonFooterWrap,
  CancelBtn,
  Container,
  FieldErrorMessage,
  Form,
  FormGroup,
  InputField,
  Label,
  LoaderContainer,
  RemoveAddressButton,
  RemoveAddressConfirmation,
  SubmitBtn,
  UpdateAddressButton
} from "./styles";
import { AddressSchema } from "./validation";
import { getAddressByCEP } from "../../services/cep";
import { cepMask, phoneMask } from "../../util/mask";

export default function Addresses() {
  const dispatch = useDispatch();
  const [addresses, setAddresses] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalNewAddress, setModalNewAddress] = useState(false);
  const [addressToRemove, setAddressToRemove] = useState(null);
  const { loading: loadingUser } = useSelector(state => state.user);
  const uuid = useSelector(state => state.user.profile?.uuid);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle("Meus Endereços");
    getAddresses();
  }, [loadingUser, uuid]);

  async function getAddresses() {
    if (!loadingUser) {
      setLoading(true);
      const response = await api.get(`/customers/${uuid}/address?_limit=100`);
      setLoading(false);
      setAddresses(response.data);
    }
  }

  async function handleRemoveAddress() {
    try {
      await api.delete(`customers/${uuid}/address/${addressToRemove.id}`);
      const addressesUpdated = addresses.filter(
        address => address.id !== addressToRemove.id
      );
      setAddresses(addressesUpdated);
      setModal(false);
    } catch (err) {
      dispatch(ErrorActions.setError("address-remove", err.response.data.user));
      setModal(false);
    }
  }

  async function handleSetSelectedAddress(id) {
    setIsUpdate(id !== 0)

    if (id !== 0) {
      setAddressId(id)
      setTitle("Meu endereço");
      const { data } = await api.get(
        `/customers/${uuid}/address/${id}`
      );
      setInitialValues({
        nome_contato: data.nome_contato,
        telefone_contato: data.telefone_contato,
        cep: data.cep,
        uf: data.cidade.uf_sigla,
        cidade: data.cidade.nome,
        bairro: data.bairro,
        numero: data.numero,
        rua: data.endereco,
        complemento: data.complemento,
        ibge: data.cidade.ibge_id
      });
      setIsUpdate(true);
    } else {
      setTitle("Adicionar novo endereço");
      setInitialValues({
        nome_contato: "",
        telefone_contato: "",
        cep: "",
        uf: "",
        cidade: "",
        bairro: "",
        numero: "",
        rua: "",
        complemento: "",
        ibge: ""
      });
    }

    setModalNewAddress(true);
  }

  async function handleCloseSelectedAddress() {
    setModalNewAddress(false);
  }

  function handleSetAddressModal(address) {
    setAddressToRemove(address);
    setModal(true);
  }

  function handleSubmit(values) {
    const {
      nome_contato,
      telefone_contato,
      cep,
      uf,
      cidade,
      bairro,
      numero,
      rua,
      complemento,
      ibge
    } = values;

    const telefone = telefone_contato.replace(/[^a-zA-Z0-9]/g, "");
    const cepWithoutMask = cep.replace(/[^a-zA-Z0-9]/g, "");

    if (!isUpdate) {
      dispatch(
        AddressActions.addressStoreRequest(
          {
            nome_contato,
            telefone_contato: telefone,
            cep: cepWithoutMask,
            uf,
            cidade,
            bairro,
            numero,
            rua,
            complemento,
            ibge
          },
          false
        )
      );
      getAddresses();
      setModalNewAddress(false);
      return;
    }

    dispatch(
      AddressActions.addressUpdateRequest({
        nomeContato: nome_contato,
        telefoneContato: telefone,
        cep: cepWithoutMask,
        uf,
        cidade,
        bairro,
        numero,
        rua,
        complemento,
        ibge,
        addressId
      })
    );
    getAddresses();
    setModalNewAddress(false);
  }

  return (
    <Container>
      {!loading && addresses && addresses.map(address => (
        <AddressesManagement key={address.id}>
          <div className="address-data">
            <div className="address-card-owner">
              <strong>{address.nome_contato}</strong>
              <span>Responsável</span>
            </div>

            <div className="address-card-data">
              <p>
                {address.endereco}, {address.bairro} {address.complemento},{" "}
                {address.numero} - {address.cidade.nome}/{address.cidade.uf_sigla}{" "}
              </p>
              <p>
                CEP: {address.cep.replace(/(\d{5})(\d{3})/g, "$1-$2")}
              </p>
            </div>
          </div>
          <div className="address-buttons">
            <UpdateAddressButton onClick={() => handleSetSelectedAddress(address.id)}>
              <img src={refresh} alt="Update"></img>
              Atualizar Endereço
            </UpdateAddressButton>

            <RemoveAddressButton onClick={() => handleSetAddressModal(address)}>
              <FiTrash2 />
              Remover
            </RemoveAddressButton>
          </div>
        </AddressesManagement>
      ))}
      {loading && (
        <LoaderContainer>
          <Loader type="ThreeDots" color="#00b2a9" height={40} width={40} />
        </LoaderContainer>
      )}
      <p>
        {addresses.length === 0 && !loading && "Nenhum endereço cadastrado"}
      </p>
      <Error errorKey="address-remove" style={{ marginBottom: 30 }} />
      <div className="address-panel-header">
        <ButtonFooterWrap
          id="button-finalizar-pagamento"
          onClick={() => handleSetSelectedAddress(0)}
          type="button">
          Adicionar novo endereço
        </ButtonFooterWrap>
      </div>

      {modalNewAddress && (
        <AddressForm>
          <div>
            <div>
              <img src={close} onClick={handleCloseSelectedAddress} />
              <h1>{title}</h1>
            </div>
            <Error errorKey="create-update-address" style={{ marginBottom: 20 }} />
            {initialValues && (
              <Formik
                initialValues={initialValues}
                validationSchema={AddressSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  errors,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  handleReset,
                  isSubmitting,
                  isValid
                }) => (
                  <>
                    <Form onSubmit={handleSubmit}>

                      <div className="groupLine">

                        <FormGroup className="smallField">
                          {errors.cep && touched.cep ? (
                            <FieldErrorMessage>{errors.cep}</FieldErrorMessage>
                          ) : null}

                          <MaskedInput
                            invalid={errors.cep && touched.cep}
                            id="cep"
                            type="text"
                            name="cep"
                            mask={cepMask}
                            onChange={handleChange}
                            onKeyUp={async () => {
                              const cep = values.cep.replace(/[^a-zA-Z0-9]/g, "");
                              if (cep.length === 8) {
                                const { data } = await getAddressByCEP(cep);
                                if (data.erro) {
                                  setFieldValue("uf", "");
                                  setFieldValue("cidade", "");
                                  setFieldValue("rua", "");
                                  setFieldValue("bairro", "");
                                  setFieldValue("ibge", "");
                                } else {
                                  setFieldValue("uf", data.uf || "");
                                  setFieldValue("cidade", data.localidade || "");
                                  setFieldValue("rua", data.logradouro || "");
                                  setFieldValue("bairro", data.bairro || "");
                                  setFieldValue("ibge", data.ibge || "");
                                }
                              }
                            }}
                            value={values.cep}
                            onBlur={handleBlur}
                            placeholder="Ex: _____-___"
                            render={(ref, props) => (
                              <InputField ref={input => ref(input)} {...props} />
                            )}
                          />

                          <Label>CEP</Label>
                        </FormGroup>

                      </div>

                      <div className="groupLine">

                        <FormGroup>
                          {errors.rua && touched.rua ? (
                            <FieldErrorMessage>{errors.rua}</FieldErrorMessage>
                          ) : null}

                          <InputField
                            invalid={errors.rua && touched.rua}
                            id="rua"
                            type="text"
                            name="rua"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.rua}
                            placeholder="Ex: Rua/Av. Fulano da Silva"
                          />
                          <Label>Rua/Avenida</Label>
                        </FormGroup>

                        <FormGroup className="smallField marginLeft">
                          {errors.numero && touched.numero ? (
                            <FieldErrorMessage>{errors.numero}</FieldErrorMessage>
                          ) : null}

                          <InputField
                            invalid={errors.numero && touched.numero}
                            id="numero"
                            type="text"
                            name="numero"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.numero}
                            placeholder="Ex: 1191 ou s/n"
                          />
                          <Label>Número</Label>
                        </FormGroup>

                      </div>

                      <div className="groupLine">

                      <FormGroup>
                          {errors.cidade && touched.cidade ? (
                            <FieldErrorMessage>{errors.cidade}</FieldErrorMessage>
                          ) : null}

                          <InputField
                            disabled
                            invalid={errors.cidade && touched.cidade}
                            id="cidade"
                            type="text"
                            name="cidade"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cidade}
                            placeholder="Ex: Tubarão"
                          />
                          <Label>Cidade</Label>
                        </FormGroup>

                        <FormGroup className="smallField marginLeft">
                          {errors.uf && touched.uf ? (
                            <FieldErrorMessage>{errors.uf}</FieldErrorMessage>
                          ) : null}

                          <InputField
                            invalid={errors.uf && touched.uf}
                            id="uf"
                            type="uf"
                            name="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled
                            value={values.uf}
                            placeholder="Ex: SC"
                          />
                          <Label>UF</Label>
                        </FormGroup>

                      </div>

                      <FormGroup>
                        {errors.bairro && touched.bairro ? (
                          <FieldErrorMessage>{errors.bairro}</FieldErrorMessage>
                        ) : null}

                        <InputField
                          invalid={errors.bairro && touched.bairro}
                          id="bairro"
                          type="text"
                          name="bairro"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.bairro}
                          placeholder="Ex: Santo Antonio de Pádua"
                        />
                        <Label>Bairro</Label>
                      </FormGroup>

                      <FormGroup>
                        {errors.complemento && touched.complemento ? (
                          <FieldErrorMessage>
                            {errors.complemento}
                          </FieldErrorMessage>
                        ) : null}

                        <InputField
                          invalid={errors.complemento && touched.complemento}
                          id="complemento"
                          type="text"
                          name="complemento"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.complemento}
                          placeholder="Ex: Andar/Apartamento (opcional)"
                        />
                        <Label>Complemento</Label>
                      </FormGroup>

                      <div className="groupLine">
                        <FormGroup>
                          {errors.nome_contato && touched.nome_contato ? (
                            <FieldErrorMessage>
                              {errors.nome_contato}
                            </FieldErrorMessage>
                          ) : null}

                          <InputField
                            invalid={errors.nome_contato && touched.nome_contato}
                            id="nome_contato"
                            type="text"
                            name="nome_contato"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.nome_contato}
                            placeholder="Ex: Fulano da Silva"
                          />
                          <Label>Nome para contato</Label>
                        </FormGroup>

                        <FormGroup className="marginLeft">
                          {errors.telefone_contato && touched.telefone_contato ? (
                            <FieldErrorMessage>
                              {errors.telefone_contato}
                            </FieldErrorMessage>
                          ) : null}

                          <MaskedInput
                            name="telefone_contato"
                            type="text"
                            placeholder="Ex: (__) ____-____"
                            mask={phoneMask}
                            invalid={
                              errors.telefone_contato && touched.telefone_contato
                            }
                            id="registerNumber"
                            value={values.telefone_contato}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            render={(ref, props) => (
                              <InputField ref={input => ref(input)} {...props} />
                            )}
                          />

                          <Label>Telefone Contato</Label>
                        </FormGroup>
                      </div>

                      <div className="btnArea">
                        <SubmitBtn disabled={!isValid} type="submit">
                          {loading ? "Salvando alterações..." : "Salvar alterações"}
                        </SubmitBtn>
                        <CancelBtn onClick={handleCloseSelectedAddress}>
                          Cancelar
                        </CancelBtn>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            )}
          </div>
        </AddressForm>
      )}

      {modal && (
        <RemoveAddressConfirmation>
          <div>
            <div className="content">
              <h1>Excluir endereço</h1>

              <p>Você realmente deseja excluir este endereço?</p>
            </div>

            <div className="footer">
              <button onClick={() => setModal(false)} className="cancel-btn">
                Cancelar
              </button>
              <button
                onClick={() => handleRemoveAddress()}
                className="remove-btn"
              >
                Sim, desejo
              </button>
            </div>
          </div>
        </RemoveAddressConfirmation>
      )}
    </Container>
  );
}
