import React, { forwardRef, useImperativeHandle } from "react";
import { ContainerInput, ContainerPassword, FieldErrorMessage, FormGroup, ImageInput, Input, InputPassword, Label, StyledSpan } from "./styles";
import Eye from "../../assets/images/eye.svg";
import CloseEye from "../../assets/images/closeEye.svg";

const InputCustom = forwardRef(
    (
      {
        id,
        name,
        type,
        description,
        value,
        onChange,
        onBlur,
        isInvalid,
        errors,
        className,
        onClickSpan,
        placeholder,
        textTransform,
        autoComplete,
      },
      ref
    ) => {
    const [input, setInput] = React.useState("");
    const [inputError, setInputError] = React.useState("");
    const [applied, setApply] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const[disabled, setDisable] = React.useState(false);    
    const toggleShowPassword = () => setShowPassword(!showPassword);

    useImperativeHandle(ref, () => ({
        triggerResetInput() {
          if (onClickSpan) {
            applyClick();
          }
        },
      }));

    function applyClick(){            
        if(!applied){
            if(input.value && input.value !== ''){
                if(onClickSpan)
                    onClickSpan(input.value.toUpperCase());

                setInputError("");
                setDisable(true);
                setApply(true);
            }        
            else
                setInputError("Preencha o campo antes de aplicar");
        } else {
            if(onClickSpan)
                onClickSpan('');
            input.value = '';
            setDisable(false);
            setApply(false);
        }
    }

    return (
        <FormGroup className={className}>
            {(isInvalid || inputError) ? (
                <FieldErrorMessage>{ inputError !== "" ? inputError : errors}</FieldErrorMessage>
            ) : null}
            <ContainerInput>
                {type === "password" ? (
                    <InputPassword
                        invalid={isInvalid}
                        id={id}
                        type={showPassword ? "text" : "password"}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                    />
                ) : (
                    <Input
                        invalid={isInvalid}
                        id={id}
                        type={type}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        onInput={e => setInput(e.target)}
                        value={value}
                        placeholder={placeholder}
                        disabled={disabled}
                        style={{textTransform: textTransform}}
                        autoComplete={autoComplete}
                    />
                )}
                {type === "password" && (
                    <ImageInput onClick={toggleShowPassword} src={type === "password" && showPassword ? Eye : CloseEye} alt={type === "password" && showPassword ? "Eye" : "CloseEye"} />
                )}
                {type === "apply" && (
                    <StyledSpan onClick={applyClick} className={disabled ? "redFont" : ""}>{applied ? "Remover" : "Aplicar"}</StyledSpan>
                )}
            </ContainerInput>
            <Label htmlFor={id}>{description}</Label>
        </FormGroup>
    );
});

export default InputCustom;