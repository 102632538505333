import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import imgSetaAvancar from '../../assets/images/seta-avancar.svg';
import imgSetaVoltar from '../../assets/images/seta-voltar.svg';
import { Container } from './styles';
import { useSelector } from "react-redux";
import api from '../../services/api';


export default function Categories({ }) {
  const { data } = useSelector(state => state.store);
  const getPageSize = (divisor) => {
    const size = Math.abs(15 / divisor);
    const intSize = parseInt(size);
    if (size > intSize)
      return intSize + 1;
    else
      return intSize;
  };

  const [categoriesWithTopImage, setCategoriesWithTopImage] = useState([]);
  const [indexScroll, setIndexScroll] = useState(0)
  const [pageSize, setPageSize] = useState(getPageSize(8))

  const divCategories = React.createRef();

  useEffect(() => {
    getCategoriesWithTopImages();
  }, []);

  const getCategoriesWithTopImages = async () => {
    try {
      const response = await api.get('categories/top-images')
      if (response.status === 200) {
        setCategoriesWithTopImage(response.data)
      } else {
        setCategoriesWithTopImage([])
      }
    } catch (err) {
      console.log(err)
      setCategoriesWithTopImage([])
    }
  }

  const handleWindowResize = () => {
    if (window.innerWidth >= 1025) {
      setPageSize(getPageSize(8));
    } else if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
      setPageSize(getPageSize(6));
    } else if (window.innerWidth >= 481 && window.innerWidth <= 767) {
      setPageSize(getPageSize(4));
    } else if (window.innerWidth >= 320 && window.innerWidth <= 480) {
      setPageSize(getPageSize(2));
    }

    setIndexScroll(0);
  };

  const handleNextPage = () => {
    if (indexScroll < (pageSize - 1))
      handlePage(indexScroll + 1);
  }

  const handleBackPage = () => {
    if (indexScroll > 0)
      handlePage(indexScroll - 1);
  }

  const handlePage = (index) => {
    const left = (divCategories.current.scrollWidth / pageSize) * index;

    divCategories.current.scrollTo({
      left: left,
      behavior: 'smooth',
      duration: 7000
    });
  };

  const handleScroll = (event) => {
    const proporcionalWidth = divCategories.current.scrollWidth / pageSize;
    const index = Math.round(event.target.scrollLeft / proporcionalWidth);
    setIndexScroll(index);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    handleWindowResize();

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  if (!categoriesWithTopImage || categoriesWithTopImage.length === 0) {
    return null
  }

  return (
    <Container>
      <>
        <h2>{"Categorias"}</h2>
        <div ref={divCategories} className='categories' onScroll={handleScroll}>
          {categoriesWithTopImage && categoriesWithTopImage.map((highlight) => (
            <Link key={highlight.id} to={`/produtos/${highlight.tipo === "C" ? "categoria" : "subcategoria"}/${highlight.url}/${highlight.id}`}>
              <div className='category'>
                <img src={highlight.base64Imagem} alt={highlight.nome} />
              </div>
              {highlight.nome}
            </Link>
          ))}
        </div>
        <div className='actions'>
          <ul>
            <li onClick={handleBackPage}>
              <img src={imgSetaVoltar} alt="Voltar" />
            </li>
            {pageSize && (
              Array.from(Array(parseInt(pageSize)).keys()).map((item, index) => (
                <li key={index} onClick={() => handlePage(index)} >
                  <span key={index} className={indexScroll == index ? "active" : ""}></span>
                </li>
              ))
            )}
            <li onClick={handleNextPage}>
              <img src={imgSetaAvancar} alt="Avançar" />
            </li>
          </ul>
        </div>
      </>
    </Container>
  );
}
