/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from "react";
import { FiArrowRight, FiMenu } from "react-icons/fi";
import { MdSearch } from "react-icons/md";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ThemeContext } from "styled-components";
import imgMenu from "../../assets/images/Menu.svg";
import carrinhoCheio from "../../assets/images/carrinho-cheio.svg";
import carrinhoVazio from "../../assets/images/carrinho-vazio.svg";
import imgFechar from "../../assets/images/close-popup.svg";
import user from "../../assets/images/no-user.svg";
import searchSvg from "../../assets/images/search.svg";
import useOutsideClick from "../../hooks/useOutsideClick";
import api from "../../services/api";
import { Creators as SearchProductsActions } from "../../store/ducks/search";
import { Creators as SideMenuActions } from "../../store/ducks/sideMenu";
import { Creators as SuggestActions } from "../../store/ducks/suggest";
import HeaderPromotion from "../HeaderPromotion";
import SelectStoreHeader from "../SelectStoreHeader";
import SideMenu from "../SideMenu";
import {
  Cart,
  CategoriesMobile,
  // CategoryContainer,
  CategoryModalContainer,
  Center,
  ContainerSearchMobile,
  Helper,
  LeftSideCategories,
  ListSubcategoriesMobile,
  LoaderContainer,
  Menu,
  MobileSearchForm,
  ModalContainer,
  Navigation,
  RightSideCategories,
  SearchForm,
  Signed,
  Suggest,
  User
} from "./styles";
import { company } from "../../util/company";


export default function Header() {
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const suggestRef = useRef(null);
  const categoriaRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState({})
  const [selectedSubCategory, setSelectedSubCategory] = useState({})
  const { suggestions } = useSelector(state => state.suggest);
  const { profile, loading: userLoading } = useSelector(state => state.user);
  const { signed } = useSelector(state => state.auth);
  const cartItems = useSelector(state => state.cart.data);
  const [showCategories, setShowCategories] = useState(false);
  const headerRef = useRef(null);
  const { searchTerm: searchTermReducer } = useSelector(state => state.search);
  const { dataNewProducts: products } = useSelector(
    state => state.products
  );
  const { data } = useSelector(state => state.store);
  const [showMenuCategoriasMobile, setShowMenuCategoriasMobile] = useState(false)

  useEffect(() => {
    window.onscroll = () => {
      if (suggestions.length === 0) {
        // scrollFunction();
      }
    };
  }, [suggestions.length]);

  useOutsideClick(suggestRef, () => {
    if (suggestions.length > 0) {
      dispatch(SuggestActions.clearSuggestions());
    }
  });

  useOutsideClick(categoriaRef, () => {
    if (showCategories) {
      setShowCategories(false);
    }
  });

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      if (document.visibilityState === "hidden") {
        window.location.reload(false);
      }
    });
  }, []);

  useEffect(() => {
    api.get('/categories').then(response => {
      setSelectedCategory(response.data[0])

      setCategories(response.data)
    })
  }, [])

  function handleSuggestion(event, url = null) {
    if (url) {
      dispatch(SuggestActions.clearSuggestions());
      history.push("/produtos/visualizar/" + url);
      setSearchTerm("");
    }
    window.location.reload()
  }

  function handleSubmit(event, suggest = null) {
    event.preventDefault();
    const term = suggest !== null ? suggest : searchTerm;

    if (searchTermReducer !== "") {
      dispatch(SuggestActions.clearSuggestions());
      history.push("/produtos/buscar/" + term);
      setSearchTerm("");
    }
    window.location.reload()
  }

  function setSearchTermState(searchTerm) {
    if (searchTerm === "") {
      dispatch(SuggestActions.clearSuggestions());
    } else {
      dispatch(SearchProductsActions.setSearchTerm(searchTerm));
    }
  }

  function doSearch(evt) {
    const searchTerm = evt.target.value;
    setSearchTerm(searchTerm);

    setSearchTermState(searchTerm)
  }

  function getHighlightedText(text, higlight) {
    let parts = text.split(new RegExp(`(${higlight})`, "i"));
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === higlight.toLowerCase()
                ? { fontWeight: "500" }
                : {}
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  }


  function handleHoverCategories(category) {
    setSelectedCategory(category)
  }

  function handleHoverSubCategories(subCategory) {
    setSelectedSubCategory(subCategory)
  }


  return (
    <>
      <HeaderPromotion visible={false} />
      {/* <HeaderLocationDelivery /> */}
      <SelectStoreHeader />
      <Helper id="helper" visible={showSearch} />
      <Menu ref={headerRef} visible={showSearch} hidden={false}>
        <Center>
          <div className="menu-btn-abrir">
            <span onClick={() => {
              setShowMenuCategoriasMobile(true)
            }}>
              <img src={imgMenu} alt={"Abrir"} />
            </span>
          </div>
          <div className="logo">
            <button
              onClick={() => {
                dispatch(SideMenuActions.toggleSideMenu("openWithButton"));
              }}
              id="menu"
            >
              <FiMenu size={30} />
            </button>
            <Link to="/">
              <img
                src={`${process.env.REACT_APP_API_URL}/companies/images/${data?.empresa?.url}/logos/logo.png`}
                title={data?.empresa?.nome}
                alt={`${data?.empresa?.nome}`}
              />
            </Link>
          </div>

          <SearchForm
            onSubmit={handleSubmit}
            openSuggest={suggestions.length > 0}
          >
            <div className="search-container">
              <img
                src={searchSvg}
                alt="Procurar"
              />
              <input
                type="search"
                value={searchTerm}
                placeholder="O que você procura?"
                name="search"
                onChange={e => doSearch(e)}
                autoComplete="off"
                inputMode="search"
              />

              {suggestions.length > 0 && window.innerWidth >= 900 && (
                <Suggest ref={suggestRef}>
                  {suggestions &&
                    suggestions.map((suggest, index) => (
                      <li
                        key={index}
                        onClick={e => handleSuggestion(e, suggest.url)}
                      >
                        {getHighlightedText(suggest.descricao, searchTerm)}
                        <MdSearch />
                      </li>
                    ))}
                </Suggest>
              )}
            </div>
          </SearchForm>

          <Navigation>
            <Cart id="carrinho">
              <Link to="/carrinho" title="Minha Cesta">
                {cartItems?.length > 0 ? (
                  <>
                    <img src={carrinhoCheio} width={50} alt={"Carrinho"}></img>
                    <span>{cartItems.length}</span>
                  </>
                ) : <img src={carrinhoVazio} width={50} alt={"Carrinho"}></img>}

              </Link>

            </Cart>

            {!signed && !userLoading && (
              <User>
                <Link to="/entrar" title="Login">
                  <img src={user} alt="Usuário"></img>
                </Link>
                <Link to="/entrar" title="Login">
                  <div className="user-text">
                    <b>Olá,</b>
                    Faça o login
                  </div>
                </Link>
              </User>
            )}

            {signed && userLoading && (
              <Signed>
                <LoaderContainer>
                  <Loader
                    type="Oval"
                    color={theme.colors.primary}
                    height={16}
                    width={16}
                  />
                </LoaderContainer>
              </Signed>
            )}

            {signed && !userLoading && profile !== null && (
              <User>
                <Link to="/painel" title="Meus dados">
                  <img src={user} alt="Usuário"></img>
                </Link>
                <Link to="/painel" title="Meus dados">
                  <div className="user-text">
                    <b>Olá,</b>
                    {profile && profile.nome.includes(" ")
                      ? profile.nome.substring(0, profile.nome.indexOf(" "))
                      : profile.nome}
                  </div>
                </Link>
              </User>
            )}
            {/* <ToggleSearch onClick={() => toggleSearchBar()}>
            <MdSearch />
          </ToggleSearch> */}


          </Navigation>

        </Center>
        <ContainerSearchMobile>
          <MobileSearchForm
            openSuggest={suggestions.length > 0}
            onSubmit={handleSubmit}
            id="mobile-search-form"
          >
            <div className="search-container">
              <img
                src={searchSvg} alt="Procurar"
              />
              <input
                type="search"
                value={searchTerm}
                placeholder="O que você procura?"
                name="search-mobile"
                onChange={e => doSearch(e)}
                autoComplete="off"
                inputMode="search"
              />

              {suggestions.length > 0 && window.innerWidth <= 900 && (
                <Suggest ref={suggestRef}>
                  {suggestions &&
                    suggestions.map((suggest, index) => (
                      <li
                        key={index}
                        onClick={e => handleSuggestion(e, suggest.url)}
                      >
                        {suggest.descricao}
                        <MdSearch />
                      </li>
                    ))}
                </Suggest>
              )}
            </div>
          </MobileSearchForm>
        </ContainerSearchMobile>
        {(showCategories && window.innerWidth >= 500) ? (
          <CategoryModalContainer id="categoryContainer">
            <ModalContainer ref={categoriaRef}>
              <LeftSideCategories>
                {categories && categories.map(category => (
                  <li>
                    <a
                      style={{ background: (category.id === selectedCategory.id) ? '#eee' : 'none' }}
                      href={`/produtos/categoria/${category.descricao}/${category.id}`}
                      onClick={() => handleHoverCategories(category)}
                      onMouseOver={() => handleHoverCategories(category)}>
                      {category.descricao}
                    </a>
                  </li>
                ))}
              </LeftSideCategories>
              <RightSideCategories>
                {selectedCategory.subcategorias && selectedCategory.subcategorias.map(subcategory => (
                  <li>
                    <a
                      href={`/produtos/subcategoria/${subcategory.descricao}/${subcategory.id}`}
                      style={{ background: (subcategory.id === selectedSubCategory.id) ? '#eee' : 'none' }}
                      onClick={() => handleHoverSubCategories(subcategory)}
                      onMouseOver={() => handleHoverSubCategories(subcategory)}
                    >
                      {subcategory.descricao}
                    </a>
                  </li>
                ))}
              </RightSideCategories>
            </ModalContainer>
          </CategoryModalContainer>
        ) : (showCategories && window.innerWidth <= 500) && (
          <CategoryModalContainer id="categoryContainer">
            <ModalContainer >
              <CategoriesMobile>
                {(categories) && categories.map(category => {
                  if (category.subcategorias.length >= 1) {
                    return (
                      (
                        <li>
                          <a
                            id="category"
                            href={`/produtos/categoria/${category.descricao}/${category.id}`}
                            onClick={() => handleHoverCategories(category)}
                            onMouseOver={() => handleHoverCategories(category)}>
                            {category.descricao}
                          </a>
                          {category.subcategorias.map(sub => (
                            <ListSubcategoriesMobile
                              href={`/produtos/subcategoria/${sub.descricao}/${sub.id}`}
                              onClick={() => handleHoverSubCategories(sub)}
                              onMouseOver={() => handleHoverSubCategories(sub)}
                            >
                              <p>
                                {sub.descricao}
                              </p>
                              <FiArrowRight size={30} />
                            </ListSubcategoriesMobile>
                          ))}
                        </li>
                      )
                    )
                  }
                })}
              </CategoriesMobile>
            </ModalContainer>
          </CategoryModalContainer>
        )}
        <div className={`menu-categorias ${showMenuCategoriasMobile ? "showMenuMobile" : ""}`}>
          <div className="menu-topo">
            <h2>Menu</h2>
            <img src={imgFechar} alt={"Fechar"} onClick={() => {
              setShowMenuCategoriasMobile(false)
            }} />
          </div>
          <ul>
            <li>
              <Link onClick={() => {
                setShowMenuCategoriasMobile(false)
              }} to={`/produtos/categoria/medicamentos/${data && data.empresa && data.empresa.url === company.MSV ? "78" : "1"}`}>Medicamentos</Link>
            </li>
            <li >
              <Link onClick={() => {
                setShowMenuCategoriasMobile(false)
              }} to={`/produtos/categoria/pais-e-filhos/${data && data.empresa && data.empresa.url === company.MSV ? "73" : "2"}`}>Pais e Filhos</Link>
            </li>
            <li>
              <Link onClick={() => {
                setShowMenuCategoriasMobile(false)
              }} to={`/produtos/categoria/cuidados-pessoais/${data && data.empresa && data.empresa.url === company.MSV ? "75" : "3"}`}>Cuidados Pessoais</Link>
            </li>
            <li>
              <Link onClick={() => {
                setShowMenuCategoriasMobile(false)
              }} to={`/produtos/categoria/beleza/${data && data.empresa && data.empresa.url === company.MSV ? "74" : "4"}`}>Beleza</Link>
            </li>
            <li>
              <Link onClick={() => {
                setShowMenuCategoriasMobile(false)
              }} to={`/produtos/categoria/saude/${data && data.empresa && data.empresa.url === company.MSV ? "72" : "5"}`}>Saúde</Link>
            </li>
            <li>
              <Link onClick={() => {
                setShowMenuCategoriasMobile(false)
              }} to={`/produtos/categoria/alimentos/${data && data.empresa && data.empresa.url === company.MSV ? "76" : "6"}`}>Alimentos</Link>
            </li>
            <li>
              <Link onClick={() => {
                setShowMenuCategoriasMobile(false)
              }} to={'/'}>Todos</Link>
            </li>
          </ul>
        </div>
      </Menu>


      <SideMenu />
    </>
  );
}
