import styled from "styled-components";

export const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  display: flex;
  min-height: calc(100vh - 355px);
  justify-content: center;
  margin: 0 auto;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 12px;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const NoOrders = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  p {
    color: ${(props) => props.theme.colors.bodyLight};
    font-size: 1.8rem;
  }
`;

export const Wrap = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 56px;

  @media (max-width: 768px){
    margin-bottom: 20px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    padding-top: 24px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.neutral100};

    :hover{
      border-color: ${(props) => props.theme.colors.primary}
    }
  }

  .item-content {
    width: 100%;
    display: flex;
    font-size: 1.6rem;
    align-items: center;
    justify-content: center;
    min-height: 85px;
    line-height: 25px;
    color: ${(props) => props.theme.colors.bodyLight};

    .header-content {
      display: flex;
      width: 100%;
      align-items: flex-start;
      :hover{
        cursor: pointer;
        color: ${(props) => props.theme.colors.primary}
      }
      @media (max-width: 767px){
        flex-direction: column;
      }
    }

    .info-content {
      display: flex;
      width: 100%;

      .info-area{
        display: flex;
        width: 100%;
        justify-content: space-between;

        > div{
          width: 50%;
        }
        @media (max-width: 805px){
          flex-wrap: wrap;
        }
      }

      .button-area{
        display: flex;

        @media (max-width: 767px){
          width: 100%;
        }
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 22px;

      .item-id, .item-date, .item-expected-date{
        color: ${(props) => props.theme.colors.neutral600};
        font-size: 13px;
        display: flex;
        flex-direction: column;

        > span {
          strong {
            color: ${(props) => props.theme.colors.neutral600};
            font-size: 16px;
          }
        }
      }

      .item-id {

        > span {
          strong {
            font-weight: 500;
          }
        }
      }

      .item-expected-date {
        > span {
          strong {
            font-weight: 400;
          }
        }
      }

      .item-date {
        > span {
          strong {
            font-weight: 400;
          }
        }
      }

      .item-amount {
        color: ${(props) => props.theme.colors.neutral600};
        display: flex;
        flex-direction: column;
        font-size: 13px;

        > span {
          strong {
            color: ${(props) => props.theme.colors.neutral600};
            font-size: 16px;
            font-weight: 500;
          }
        }
      }

      .items-name {
        width: 81%;
        color: ${(props) => props.theme.colors.neutral900};
        @media (max-width: 767px){
          width: 100%;
        }
      }

      .item-status {
        width: 27%;
        display: flex;
        justify-content: end;
        @media (max-width: 767px){
          width: 100%;
          justify-content: flex-start;
          margin-top: 8px;
        }

        > span {
          display: flex;
          flex-direction: column;
          background-color: ${(props) => props.theme.colors.error};
          color: ${(props) => props.theme.colors.white};
          font-weight: 500;
          font-size: 14px;
          padding: 0 5px;
          border-radius: 4px;
        }

        >.PF{
          background-color: ${(props) => props.theme.colors.primary};
        }

        >.PE, >.PA{
          background-color: ${(props) => props.theme.colors.alertsuccess};
        }

        >.AP, >.PS, >.PL, >.AR, >.AE {
          background-color: ${(props) => props.theme.colors.warning};
        }

        >.PC, >.PT, >.FP {
          background-color: ${(props) => props.theme.colors.error};
        }
      }
    }

    .item-button {
      width: 100%;
      align-self: flex-end;

      button {
        justify-content: end;
        align-items: center;
        display: flex;
        height: 40px;
        text-align: center;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        max-width: 200px;
        border: none;
        border-radius: 2px;
        color: ${(props) => props.theme.colors.primary};
        font-size: 14px;
        font-weight: 700;
        @media (max-width: 767px){
          margin-top: 8px;
        }
      }
      &.repeat-order {
        cursor: pointer;
        img {
          margin-right: 5px;
        }

        button {
          background-color: transparent;
        }
      }
    }
  }
`;
