import React, { useState, useEffect } from "react";
import { SignUpSchema } from "./validation";
import { Formik } from "formik";
import {
  AcceptTerms,
  Bottom,
  Title,
  Container,
  StyledLinkLogo,
  Form,
  FormTitle,
  SignIn,
  StyledMaskedInputCustom
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { Creators as SignUpActions } from "../../store/ducks/signup";
import { setTitle } from "../../services/browser";
import InputCustom from "../../components/InputCustom";
import InputRadioCustom from "../../components/InputRadioCustom";
import ButtonPrimary from "../../components/ButtonPrimary";
import { Link } from "react-router-dom";
import TermsOfUseModal from "../../components/TermsOfUseModal";
import PrivacyPolicyModal from '../../components/PrivacyPolicyModal';
import { ToastContainer } from "react-toastify";
import { cpfMask, dateMask, phoneMask } from "../../util/mask";

export default function SignUp({ history }) {
  const { data } = useSelector(state => state.store);
  const dispatch = useDispatch();
  const [showTermsOfUse, setTermsOfUse] = useState(false);
  const [showPrivacyPolicy, setPrivacyPolicy] = useState(false);

  const { programa_fidelidade_nome, politica_privacidade } = useSelector(state =>
    state.store.data == null ? "" : state.store.data.empresa
  );

  useEffect(() => {
    setTitle("Nova conta");
  }, []);

  function handleSubmit({
    cpf,
    nome,
    email,
    senha,
    dataNascimento,
    celular,
    genero,
    programaFidelidade,
    lgpd_aceite
  }) {
    dispatch(
      SignUpActions.signUpRequest(
        cpf,
        nome,
        email,
        senha,
        dataNascimento,
        celular,
        genero,
        programaFidelidade,
        lgpd_aceite
      )
    );
  }

  return (
    <Container>
      <TermsOfUseModal visible={showTermsOfUse} closeModal={() => setTermsOfUse(false)} />
      <PrivacyPolicyModal visible={showPrivacyPolicy} closeModal={() => setPrivacyPolicy(false)} />
      <ToastContainer autoClose={5000} />
      {data && data.empresa && data.empresa.url && (
        <StyledLinkLogo to="/">
          <img
            src={`${process.env.REACT_APP_API_URL}/companies/images/${data?.empresa?.url}/logos/logo.png`}
            title={data?.empresa?.nome}
            alt={`${data?.empresa?.nome}`}
          />
        </StyledLinkLogo>
      )}
      <Formik
        initialValues={{
          cpf: "",
          nome: "",
          email: "",
          senha: "",
          dataNascimento: "",
          celular: "",
          genero: "",
          confirmacaoSenha: "",
          programaFidelidade: false,
          lgpd_aceite: false
        }}
        validate={values => { }}
        validationSchema={SignUpSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          handleSubmit,
          handleReset,
          isSubmitting,
          isValid
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormTitle>
              <Title>Criar minha conta</Title>
            </FormTitle>
            <InputCustom id="nome" type="text" name="nome" description="Nome completo*" value={values.nome} onChange={handleChange} onBlur={handleBlur} isInvalid={errors.nome && touched.nome} errors={errors.nome} />
            <StyledMaskedInputCustom id="cpf" name="cpf" description="CPF*" value={values.cpf} onChange={handleChange} onBlur={handleBlur} isInvalid={errors.cpf && touched.cpf} errors={errors.cpf} mask={cpfMask} />
            <StyledMaskedInputCustom id="dataNascimento" name="dataNascimento" description="Data de nascimento*" value={values.dataNascimento} onChange={handleChange} onBlur={handleBlur} isInvalid={errors.dataNascimento && touched.dataNascimento} errors={errors.dataNascimento} mask={dateMask} />
            <InputRadioCustom description="Gênero*" name="genero" value={values.genero} options={[{ value: "M", label: "Masculino" }, { value: "F", label: "Feminino" }, { value: "N", label: "Não informar" }]} handleChange={handleChange} handleBlur={handleBlur} isInvalid={errors.genero && touched.genero} errors={errors.genero} />
            <InputCustom id="email" type="email" name="email" description="E-mail*" value={values.email} onChange={handleChange} onBlur={handleBlur} isInvalid={errors.email && touched.email} errors={errors.email} />
            <StyledMaskedInputCustom id="celular" name="celular" description="Celular*" value={values.celular} onChange={handleChange} onBlur={handleBlur} isInvalid={errors.celular && touched.celular} errors={errors.celular} mask={phoneMask} />
            <InputCustom id="senha" type="password" name="senha" description="Senha*" value={values.senha} onChange={handleChange} onBlur={handleBlur} isInvalid={errors.senha && touched.senha} errors={errors.senha} />
            <InputCustom id="confirmacaoSenha" type="password" name="confirmacaoSenha" description="Repetir senha*" value={values.confirmacaoSenha} onChange={handleChange} onBlur={handleBlur} isInvalid={errors.confirmacaoSenha && touched.confirmacaoSenha} errors={errors.confirmacaoSenha} />
            <Bottom>
              <AcceptTerms>
                <p>
                  <input
                    type="checkbox"
                    name="lgpd_aceite"
                    id="lgpd_aceite"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lgpd_aceite}
                    checked={values.lgpd_aceite === true}
                    style={{ marginRight: 10 }}
                  />
                  <label for="lgpd_aceite">
                    Estou de acordo com as
                    {" "}
                    <button type="button" onClick={() => setPrivacyPolicy(true)} >
                      Políticas de Privacidade
                    </button>
                    {" "}
                    e
                    {" "}
                    <button type="button" onClick={() => setTermsOfUse(true)} >
                      Termos de uso
                    </button>
                  </label>
                </p>
              </AcceptTerms>

              <ButtonPrimary text="Criar seu cadastro" disabled={!isValid} />
              <SignIn>
                Já tem cadastro?
                <Link to="/entrar">Entrar.</Link>
              </SignIn>
            </Bottom>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
