import store from "../store/index";

export const setTitle = (title, concatAppName = true) => {
  const loja = store.getState().store.data;
  
    if(loja?.empresa?.nome)
    {
      const appName = (title !== null ? ' | ' : '')  + loja?.empresa?.nome;
      document.title = (title !== null ? title : '') + (concatAppName ? appName : '');
    }
};
