import React from 'react';
import Loader from "react-loader-spinner";


import { Container } from './styles';

function Loading() {
  return (
    <Container>
      <Loader
          type="Oval"
          color="#00786A"
          height={60}
          width={60}
        />
        Carregando...
    </Container>
  );
}

export default Loading;
