import React, { useState, useEffect } from "react";
import "./styles.css";
import { Creators as ProductsActions } from "../../store/ducks/products";
import { Creators as CartActions } from "../../store/ducks/cart";
import Loader from "react-loader-spinner";
import CartaoVantagens from "../../assets/images/cartao-vantagens.png";
import HelmetCustom from "../../components/HelmetCustom";

import {
  Container,
  LoaderContainer,
  ProductShop,
  ProductComum,
  ProductPriceContainer,
  ContainerName,
  ContainerInteract,
  ContainerInteractFirstColumn,
  ContainerManufacturer,
  ProductPriceFirstColumn,
  ContainerRealPrice,
  ContainerDiscountPrice,
  ProductPriceSecondColumn,
  TextOldValue,
  TextNewValue,
  ContainerAdvantageCard,
  ContainerNewValue,
  ContainerInfoAdvantageCard,
  ContainerInfoAdvangateCardFirstColumn,
  ContainerInfoAdvantageCardSecondColumn,
  ContainerInfoEconomyWithAdvantageCard,
  TextEconomyWithAdvantageCard,
  StyledButtonSecundary,
  ContainerSpecialPricesList,
  StyledProductAlert,
  ContainerCharacteristicsProduct,
  ContainerCharacteristic,
  ContainerAddCart,
  StyledButtonPrimary,
  ContainerDatails,
  ContainerProduct,
  GalleryImagesStyled,
  ProductDescription,
  GalleryImagesDesktopStyled,
  ContainerPromo,
  ContainerWithoutStock
} from "./styles";

import { formatPrice } from "../../util/format";
import { useDispatch, useSelector } from "react-redux";
import IncrementItem from "../../components/IncrementItem";
import CardSpecialPriceProduct from "../../components/CardSpecialPriceProduct";
import ProductAlert from "../../components/ProductAlert";
import MainLayout from "../../components/MainLayout";
import { Link } from "react-router-dom";

export default function ProductDetail({ history, match }) {
  const dispatch = useDispatch();
  const { selected: product, searchLoading } = useSelector(
    state => state.products
  );
  const { data } = useSelector(state => state.store);
  const store = useSelector(state => state.storeByUser.data);
  const { loading } = useSelector(state => state.cart);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    dispatch(ProductsActions.showProductRequest(match.params.slug));
  }, [match.params.slug, product.nome]);

  const showPrice = (product) => {
    const { preco_de, preco_por } = product.precos[0];
    const { estoque_total, estoque_loja, leve_mais_pague_menos } = product;
    if ((store !== null && estoque_loja > 0) || estoque_total > 0) {
      return (
        <ProductPriceContainer>
          <ProductPriceFirstColumn>
            {preco_de !== preco_por && (
              <ContainerRealPrice>
                <span>De </span>
                <TextOldValue>{formatPrice(preco_de)}</TextOldValue>
                <span> por</span>
              </ContainerRealPrice>
            )}
            <ContainerDiscountPrice>
              <ContainerNewValue>
                <span>R$</span>
                <TextNewValue>{formatPrice(preco_por).replace('R$', ' ')}</TextNewValue>
                {leve_mais_pague_menos && (
                  <ContainerAdvantageCard>
                    <img
                      className="programa-fidelidade"
                      alt="Programa fidelidade"
                      src={CartaoVantagens}
                    />
                  </ContainerAdvantageCard>
                )}
              </ContainerNewValue>
            </ContainerDiscountPrice>
          </ProductPriceFirstColumn>
          {(
            (product.estoque_total > 0 && store === null) ||
            (product.estoque_loja > 0 && store !== null)
          ) && product.tipo !== 4 && product.tipo !== 5 && (
              <ProductPriceSecondColumn>
                <IncrementItem quantity={quantity} setQuantity={setQuantity} />
              </ProductPriceSecondColumn>
            )}
        </ProductPriceContainer>
      )
    } else if (product.tipo !== 4 || product.tipo !== 5) {
      return (
        <ContainerWithoutStock>
          <span>Sem estoque :(</span>
        </ContainerWithoutStock>
      )
    }
  };

  function addProductToCart() {
    if (quantity > 0) {
      dispatch(CartActions.addProduct(product, parseInt(quantity)));
      const newQuantity = product.quantidade + quantity;

      dispatch(
        ProductsActions.setSelectedProduct({
          ...product,
          quantidade: newQuantity
        })
      );
      setQuantity(1);
    }
  }

  const addProductToCartWithRedirect = () => {
    addProductToCart();
    history.push("/carrinho");
  }

  function showAddCart() {
    if ((
      (product.estoque_total > 0 && store === null) ||
      (product.estoque_loja > 0 && store !== null)
    ) && product.tipo !== 4 && product.tipo !== 5) {
      return (
        <ContainerAddCart>
          <StyledButtonPrimary text="Comprar" onClick={addProductToCartWithRedirect} />
          <StyledButtonSecundary text="Adicionar à cesta" onClick={addProductToCart} />
        </ContainerAddCart>

      );
    }
  }

  function showDetails(tipo) {
    if (tipo == 1) {
      return (
        <StyledProductAlert>
          <ProductAlert text={"Medicamento de notificação simplificada.\nEste produto é um medicamento. Seu uso pode trazer riscos. procure o médico e o farmacêutico. Leia a bula.\nSe persistirem os sintomas, o médico deverá ser consultado."} />
        </StyledProductAlert>
      )
    }
    else if (tipo == 4) {
      return (
        <StyledProductAlert>
          <ProductAlert text={"Venda proibida via internet.\nMedicamento sujeito a controle especial mediante retenção da receita.\nPortaria Nº 344 - 01/02/1999 - Ministério da saúde"} />
        </StyledProductAlert>
      )
    }
    else if (tipo == 5) {
      return (
        <StyledProductAlert>
          <ProductAlert text={"Venda proibida na internet.\nMedicamento sujeito a controle especial mediante a retenção de receituário simples de duas vias.\nRDC Nº 20/2011."} />
        </StyledProductAlert>
      )
    }
  }

  function showCharacteristics(product) {
    return (
      Array.isArray(product.caracteristicas) &&
        product.caracteristicas.length !== 0 ? (
        <ContainerCharacteristicsProduct>
          {product.caracteristicas.map((caracteristica, key) => (
            <ContainerCharacteristic key={key}>
              <h2>{caracteristica.titulo}</h2>
              <p>{caracteristica.descricao}</p>
            </ContainerCharacteristic>
          ))
          }
        </ContainerCharacteristicsProduct>
      ) : (
        <ContainerCharacteristicsProduct>
          <p>Não há informações sobre esse produto</p>
        </ContainerCharacteristicsProduct>
      )
    );
  }

  if (searchLoading) {
    return (
      <LoaderContainer>
        <Loader type="Oval" color="#00786A" height={40} width={40} />
      </LoaderContainer>
    );
  }

  return (
    <MainLayout history={history}>
      <HelmetCustom
        fbTitle={product.nome}
        fbUrl={`https://associadasapp.com.br/produtos/visualizar/${product.url}`}
        fbDescription={product.nome}
        fbImage={product.imagens[0].caminho_imagem}
        content={product.nome}
      />
      <Container>

        <ContainerProduct>
          <div className="route-detail">
            <p>
              <Link to="/">Início</Link> {' > '}
              {
                product?.categoria?.descricao && (
                  <>
                    <Link to={`/produtos/categoria/${encodeURIComponent(product?.categoria?.descricao.toLowerCase())}/${product?.categoria?.id}`}>{product?.categoria?.descricao}</Link>
                    {' > '}
                  </>
                )
              }

              <b>{product.nome}</b>
            </p>
          </div>
          <div className="detail-container">
            <GalleryImagesDesktopStyled productName={product.nome} images={product.imagens} />
            <ProductShop>
              <ProductDescription>
                <ContainerName>
                  <h1>{product.nome}</h1>
                </ContainerName>
                <ContainerInteract>
                  <ContainerInteractFirstColumn>
                    {/* <Rating ratingTotal={2} averageRatingNote={5} /> */}
                    {product?.fabricante && (
                      <ContainerManufacturer>
                        <span>{product.fabricante}</span>
                      </ContainerManufacturer>
                    )}
                  </ContainerInteractFirstColumn>
                  {/* <ContainerInteractSecondColumn>
                  <ButtonShare />
                  <ButtonFavorite />
                </ContainerInteractSecondColumn> */}
                </ContainerInteract>
              </ProductDescription>
              <GalleryImagesStyled productName={product.nome} images={product.imagens}>
                {product.estoque_total > 0 && product.maior_desconto > 0 && (
                  <ContainerPromo>
                    <span>
                      {parseFloat(product.maior_desconto)
                        .toString()
                        .replace(".", ",")}
                      % OFF
                    </span>
                  </ContainerPromo>
                )}
                {product.controlado && (
                  <ContainerDatails>
                    <span>VENDA SOMENTE NA LOJA</span>
                    <span>ESTE PRODUTO NÃO PODE SER COMERCIALIZADO ONLINE</span>
                  </ContainerDatails>
                )}
              </GalleryImagesStyled>
              {product.pode_vender && (
                <div className="price">
                  {showPrice(product)}
                  {product.leve_mais_pague_menos &&
                    (parseFloat(product.menor_preco.preco_de) - parseFloat(product.menor_preco.preco_por)) > 0 && (
                      <ContainerInfoAdvantageCard>
                        <ContainerInfoAdvangateCardFirstColumn>
                          <img
                            className="programa-fidelidade"
                            alt="Programa fidelidade"
                            src={CartaoVantagens}
                          />
                          <ContainerInfoEconomyWithAdvantageCard>
                            <span>Economize</span>
                            <br />
                            <TextEconomyWithAdvantageCard>{formatPrice(parseFloat(product.menor_preco.preco_de) - parseFloat(product.menor_preco.preco_por))}</TextEconomyWithAdvantageCard>
                          </ContainerInfoEconomyWithAdvantageCard>
                        </ContainerInfoAdvangateCardFirstColumn>
                        <ContainerInfoAdvantageCardSecondColumn>
                          <span>Com o nosso</span>
                          <br />
                          <span><b>cartão de vantagens</b></span>
                        </ContainerInfoAdvantageCardSecondColumn>
                      </ContainerInfoAdvantageCard>
                    )}
                  {product.leve_mais_pague_menos && (
                    <ContainerSpecialPricesList>
                      {product.preco_quantidade_minima.length > 0 &&
                        product.preco_quantidade_minima.map(product => (
                          <CardSpecialPriceProduct product={product} />
                        ))}

                      {product.preco_quantidade_multipla.length > 0 &&
                        product.preco_quantidade_multipla.map((product, key) => (
                          <CardSpecialPriceProduct product={product} key={key} />
                        ))}
                    </ContainerSpecialPricesList>
                  )}
                  {showAddCart()}
                </div>
              )}
              {showDetails(product.tipo)}
            </ProductShop>
          </div>
        </ContainerProduct>
        {showCharacteristics(product)}
      </Container >
    </MainLayout>
  );
}
