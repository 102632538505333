export const Types = {
  GET_COUPON_REQUEST: "coupon/GET_COUPON_REQUEST",
  GET_COUPON_SUCCESS: "coupon/GET_COUPON_SUCCESS",
  GET_COUPON_FAILURE: "coupon/GET_COUPON_FAILURE",
  CLEAR_COUPON: "coupon/CLEAR_COUPON",
};

const INITIAL_STATE = {
  loading: false,
  coupon: null,
  error: null
};

export default function coupon(state = INITIAL_STATE, action) {
    switch (action.type) {
      case Types.GET_COUPON_REQUEST:
        return {
        ...state,
        loading: true,
        error: null,
        coupon: null
      }
      case Types.GET_COUPON_SUCCESS: 
      return {
        ...state,
        loading: false,
        error: null,
        coupon: action.payload
      }
      case Types.GET_COUPON_FAILURE:       
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        coupon: null
      }
      case Types.CLEAR_COUPON:
      return {
        ...state,
        loading: false,
        error: null,
        coupon: null
      }
      default:
        return state;
    }
}

export const Creators = {
  getCouponRequest: (data) => ({
    type: Types.GET_COUPON_REQUEST,
    payload: { data }
  }),

  getCouponSuccess: (data) => ({
    type: Types.GET_COUPON_SUCCESS,
    payload: { data }
  }),

  getCouponFailure: (error) => ({
    type: Types.GET_COUPON_FAILURE,
    payload: { error }
  }),

  clearCoupon: () => ({
    type: Types.CLEAR_COUPON
  })
};
