import * as Yup from "yup";
import axios from "axios";
import { getAddressByCEP } from "../../services/cep";
import { telephoneIsValid } from "../../util/validation";

export const AddressSchema = Yup.object().shape({
  nome_contato: Yup.string().required("Nome não pode ser vazio"),
  rua: Yup.string().required("Rua não pode ser vazio"),
  bairro: Yup.string().required("Bairro não pode ser vazio"),
  numero: Yup.string().required("Número não pode ser vazio"),
  complemento: Yup.string(),
  telefone_contato: Yup.string().test(
    "telefone_contato",
    "Telefone incompleto",
    (strTelefone = "") => {
      return telephoneIsValid(strTelefone);
    }
  ),
  cep: Yup.string()
    .required("CEP não pode ser vazio")
    .test("cep", "CEP incompleto", async strCEP => {
      if (strCEP) {
        const cep = strCEP.replace(/[^a-zA-Z0-9]/g, "");
        return cep.length === 8;
      }
    })
    .test("cep", "O CEP informado é inexistente.", async strCEP => {
      try {
        const cep = strCEP.replace(/[^a-zA-Z0-9]/g, "");
        if (cep.length === 8) {
          const { data } = await getAddressByCEP(strCEP);
          return data.erro !== undefined ? false : true;
        }
      } catch (err) {
        return false;
      }
    })
});
