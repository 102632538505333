import styled from "styled-components";

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    padding: 10px 0;
`;

export const Label = styled.label`
  transition: all 0.3s ease;
  font-family: 'Red Hat Display', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: ${props =>
    props.invalid
      ? props.theme.colors.warning
      : props.theme.colors.neutral900};
`;

export const Input = styled.input`
    height: 45px;
    background: #ffffff;
    border: 1px solid
    ${props => (props.invalid ? props.theme.colors.warning : "#cfcdcd")};
    box-sizing: border-box;
    padding-left: 10px;
    font-size: 16px;
    outline: none;
    transition: all 0.3s ease;
    -webkit-appearance: none;
    border-radius: 8px;
    width: 100%;

    :focus {
      border: 1px solid
        ${props =>
    props.invalid
      ? props.theme.colors.warning
      : props.theme.colors.primary};
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    :focus + label {
      color: ${props =>
    props.invalid ? "red" : props => props.theme.colors.primary};
    }

    ::placeholder {
      color: ${props => props.theme.colors.bodyLight};
    }

    :disabled {
      background: #f1f1f1;
    }

    ::-webkit-input-placeholder {
      text-transform: initial;
    }

    :-moz-placeholder { 
      text-transform: initial;
    }

    ::-moz-placeholder {  
      text-transform: initial;
    }

    :-ms-input-placeholder { 
      text-transform: initial;
    }

    &:disabled{
        background-color: ${props => props.theme.colors.gray};
        color: ${props => props.theme.colors.grayDark};
    }
`;

export const ContainerInput = styled.div`
  display: inline-flex;
  position: relative;

  &:focus-within {
    & + label {
      color: ${props =>
    props.invalid ? "red" : props => props.theme.colors.primary};
    }
  }
`;

export const InputPassword = styled(Input)`
    padding-right: 42px;
`;

export const FieldErrorMessage = styled.div`
  color: ${props => props.theme.colors.warning};
  font-size: 14px;
  margin-top: 5px;
`;

export const ContainerPassword = styled.div`
  display: inline-flex;
  position: relative;


`;

export const ImageInput = styled.img`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const StyledSpan = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;  
`;