import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Cookies from "./components/Cookies";
import HelmetCustom from "./components/HelmetCustom";
import LgpdAcceptModal from "./components/LgpdAcceptModal";
import OnInit from "./components/OnInit";
import ToastOffline from "./components/ToastOffline";
import "./config/reactotron";
import { FilterProvider } from './contexts/FilterContext';
import { OrdenationProvider } from './contexts/OrdenationContext';
import useNavigatorOnLine from './hooks/useNavigatorOnline';
import Routes from "./routes";
import api from "./services/api";
import history from "./services/history";
import store from "./store";
import GlobalStyle from "./styles/global";
import associadas from "./styles/themes/associadas";

function App() {
  const isOnline = useNavigatorOnLine();
  const [user, setUser] = useState(null);
  const [lgpd, setLgpd] = useState(true);
  const signed = store.getState().auth.signed;

  useEffect(() => {
    async function getUser() {
      if(signed){
      const { data } = await api.get(`/customers/account`);
      setUser(data);
      }
    }

    getUser();
  }, []);

  return (
    <ThemeProvider theme={associadas}>
      <OrdenationProvider>
        <FilterProvider>
          <Provider store={store}>

            {!localStorage.getItem("@ConexaoGAM:Cookies") && (
              <Cookies />
            )}

            {(!user?.lgpd_aceite && user) && (
              <LgpdAcceptModal visible={lgpd} user={user} closeModal={() => setLgpd(false)} />
            )}

            <OnInit />
            <GlobalStyle />
            <HelmetCustom />
            {!isOnline && (<ToastOffline />)}

            <Router history={history}>
                <Routes />
            </Router>
          </Provider>
        </FilterProvider>
      </OrdenationProvider>
    </ThemeProvider>
  );
}

export default App;
