import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { Creators as AuthActions } from "../ducks/auth";
import { Creators as SignUpActions } from "../ducks/signup";
import api from "../../services/api";
import history from "../../services/history";
import {format} from 'date-fns'
import { Creators as CartActions } from "../ducks/cart";

export function* signUp({ payload }) {
  try {
    const {
      cpf,
      nome,
      email,
      senha,
      dataNascimento,
      celular,
      genero,
      programaFidelidade,
      lgpd_aceite
    } = payload;

    const cpfFormatted = cpf.replace(/[^\d]+/g, "");
    const celularFormatted = celular.replace(/[^\d]+/g, "");

    const { data } = yield call(api.post, "/customers", {
      cpf: cpfFormatted,
      nome,
      email,
      senha,
      dataNascimento,
      celular: celularFormatted,
      genero,
      programaFidelidade,
      lgpd_aceite,
      ip: '',
      lgpd_data_aceite: format(new Date(), 'yyyy-MM-dd')
    });

    const { accessToken, cliente, refreshToken } = data;

    yield localStorage.setItem("@ConexaoGAM:token", accessToken)
    yield localStorage.setItem("@ConexaoGAM:refreshToken", refreshToken)

    api.defaults.headers.Authorization = `Bearer ${accessToken}`;

    yield put(AuthActions.signInSuccess(accessToken, refreshToken, cliente));

    yield toast.success("Cadastro efetuado com sucesso!")
    yield put(SignUpActions.signUpSuccess());

    yield put(CartActions.getOrderAndCartFromStorage());

    history.push("/");
  } catch (err) {
    yield toast.error(err.response.data.error);
    yield put(SignUpActions.signUpFailure());
  }
}
