import React, { useState } from "react";
import { toast } from "react-toastify";
import { AddIcon, Card, Discount, ProductInfo, ProductTitle, ProductValue, QuantityWrap, RemoveIcon, SpecialPrice, SpecialPriceContainer, ThumbnailWrap } from "./styles";
import excluirItemCesta from "../../assets/images/excluir-item-cesta.svg";
import { useDispatch } from "react-redux";
import { Creators as CartActions } from "../../store/ducks/cart";
import { formatPrice } from "../../util/format";

const ProductCard = ({ index, product, allowHandleQuantity }) => {
    const showDiscount = false;
    const dispatch = useDispatch();
    const [toastId, setToastId] = useState(null);
    const [frontBasket, setFrontBasket] = useState([]);

    const notify = () => {
        if (!toast.isActive(toastId)) {
            const newToast = toast.info(`Produto removido do carrinho`, {
                position: "top-center",
                className: "toast",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setToastId(newToast);
        }
    }

    const showDiscountTemplate = (product, isMobile) => {
        return product?.leve_mais_pague_menos ||
            product?.menor_preco?.programa_fidelidade ? (
            <SpecialPriceContainer className={isMobile ? 'isMobile' : 'isDesktop'}>
                {product.maior_desconto > 0 && (
                    <div className="biggest-discount">
                        <span>
                            {parseFloat(product.maior_desconto)}% OFF
                        </span>
                    </div>
                )}
                {product?.leve_mais_pague_menos && product?.promocao_aplicada && (
                    <>
                        <SpecialPrice>
                            <span>Leve + Pague -</span>
                        </SpecialPrice>
                    </>
                )}
            </SpecialPriceContainer>
        ) : null
    };

    const showPriceProduct = (product) => {
        return (
            <div className="fidelity-price-container">
                {product?.preco_mostrar_cesta &&
                    product?.preco_mostrar_cesta?.preco_de !==
                    product?.preco_mostrar_cesta?.preco_por ? (
                    <p className="old-price-text">
                        De <span className="old-price">
                            {formatPrice(
                                parseFloat(
                                    product?.preco_mostrar_cesta?.preco_de ||
                                    product?.preco_mostrar_cesta
                                )
                            )}
                        </span>
                    </p>

                ) : null}
                <div className="fidelity-price">
                    <span className="new-price">
                        {formatPrice(
                            parseFloat(
                                product?.precos[0]?.preco_por ||
                                product?.preco_mostrar_cesta
                            )
                        )}
                    </span>
                </div>
            </div>
        );
    }

    const handleRemoveProductCart = (product, key) => {
        dispatch(CartActions.removeProduct(product, key));
        notify(product);
    };

    const handleChange = (quantity, product, key) => {
        setFrontBasket(
            frontBasket.map((product, key) =>
                key === key ? { ...product, quantidade: quantity } : product
            )
        );

        const input = document.getElementById(`qtyInput${key}`);

        if (quantity > 50) {
            input.value = 50;
            dispatch(CartActions.addProduct(product, 50, false, true, false, true));
            return;
        }

        if (quantity === "") {
            quantity = 1;
        }

        input.value = quantity;

        if (quantity && quantity > 0) {
            dispatch(
                CartActions.addProduct(
                    product,
                    parseInt(quantity) + (product?.brotherQuantity ?? 0),
                    false,
                    true,
                    false,
                    true
                )
            );
        } else if (parseInt(quantity) === 0) {
            setTimeout(() => {
                handleRemoveProductCart(product, key);
            }, 1000);
        } else if (quantity < 0) {
            input.value = parseInt(quantity) * -1;
        }
    }

    const handleDelete = (e) => {
        var evt = e || window.event;
        if (evt) {
            var keyCode = evt.charCode || evt.keyCode;
            if (keyCode === 8 || keyCode === 46 || keyCode === 17) {
                if (evt.preventDefault) {
                    evt.preventDefault();
                } else {
                    evt.returnValue = false;
                }
            }
        }
    }

    const handleFocus = (e, product, key) => {
        const input = document.getElementById(`qtyInput${key}`);
        input.focus();
        input.select();
    }

    return (
        <Card>
            <div className="container-left">
                <ThumbnailWrap>
                    <img
                        src={product.imagens && product.imagens[0].caminho_imagem}
                        alt={product.nome}
                        title={product.nome}
                    />
                </ThumbnailWrap>
                {showDiscountTemplate(product, true)}
            </div>

            <div className="container-right">
                <ProductInfo>
                    <ProductTitle
                        to={`/produtos/visualizar/${product.url}`}
                        lines={1}
                    >
                        {product.nome && product.nome.toLowerCase()}
                    </ProductTitle>

                    {showDiscountTemplate(product, false)}
                </ProductInfo>
                <div className="handle-quantity">
                    {allowHandleQuantity && (
                        <div className="excluir-item-cesta">
                            <img src={excluirItemCesta} alt="Excluir item da cesta" onClick={() => handleRemoveProductCart(product, index)} />
                        </div>
                    )}
                    <QuantityWrap>
                        {
                            allowHandleQuantity && (
                                <button
                                    className="decrement-item"
                                    onClick={() =>
                                        handleChange(product.quantidade - 1, product, index)
                                    }
                                >
                                    <RemoveIcon color={"143C97"} size={30} />
                                </button>
                            )
                        }
                        <input
                            className="qtyInput"
                            onKeyDown={handleDelete()}
                            onFocus={(e) => handleFocus(e, product, index)}
                            type="number"
                            id={`qtyInput${index}`}
                            value={product.quantidade}
                            onChange={(e) =>
                                handleChange(e.target.value, product, index)
                            }
                            name={`qtyInput${product.key}`}
                        />
                        {
                            allowHandleQuantity && (
                                <button
                                    className="increment-item"
                                    onClick={() =>
                                        handleChange(product.quantidade + 1, product, index)
                                    }
                                >
                                    <AddIcon color={"143C97"} size={30} />
                                </button>
                            )
                        }
                    </QuantityWrap>
                </div>
                <div className="container-price-item">
                    {showDiscount && product?.preco_mostrar_cesta?.percentual_desconto > 0 && (
                        <Discount>
                            <span>
                                {parseFloat(
                                    product?.preco_mostrar_cesta?.percentual_desconto * -1
                                ).toLocaleString("pt-BR")}
                                %
                            </span>
                        </Discount>
                    )}
                    <ProductValue>
                        {showPriceProduct(product)}
                    </ProductValue>
                </div>
            </div>
        </Card>
    );
}

export default ProductCard;