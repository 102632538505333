import produce from "immer";

export const Types = {
  ORDER_REQUEST: "@order/ORDER_REQUEST",
  ORDER_FAILURE: "@order/ORDER_FAILURE",
  ORDER_SUCCESS: "@order/ORDER_SUCCESS",
  ORDER_ERROR: "@order/ORDER_ERROR",
  ORDER_REQUEST_PIX_PAYMENT: "@order/ORDER_REQUEST_PIX_PAYMENT",
  ORDER_REQUEST_PIX_PAYMENT_PENDING: "@order/ORDER_REQUEST_PIX_PAYMENT_PENDING",
  RESET_ERROR_MESSAGE: "@order/RESET_ERROR_MESSAGE",
  RESET_ERROR: "@order/RESET_ERROR",
  RESET_DELIVERY_MESSAGE: "@order/RESET_DELIVERY_MESSAGE",
  SHOW_CONFIRM_ORDER_MODAL: "@order/SHOW_CONFIRM_ORDER_MODAL",
  SHOW_DELIVERY_STORE_MODAL: "@order/SHOW_DELIVERY_STORE_MODAL",
  HIDE_DELIVERY_STORE_MODAL: "@order/HIDE_DELIVERY_STORE_MODAL",
  HIDE_CONFIRM_ORDER_MODAL: "@order/HIDE_CONFIRM_ORDER_MODAL",
  CONFIRM_ORDER_REQUEST: "@order/CONFIRM_ORDER_REQUEST",
  CONFIRM_ORDER_SUCCESS: "@order/CONFIRM_ORDER_SUCCESS",
  SHOW_OPENING_HOURS_MODAL: "@order/SHOW_OPENING_HOURS_MODAL",
  HIDE_OPENING_HOURS_MODAL: "@order/HIDE_OPENING_HOURS_MODAL",
  SHOW_DELIVERY_SELECTED_STORE_MODAL: "@order/SHOW_DELIVERY_SELECTED_STORE_MODAL",
  HIDE_DELIVERY_SELECTED_STORE_MODAL: "@order/HIDE_DELIVERY_SELECTED_STORE_MODAL",
  SHOW_OUT_OF_STOCK_ITEMS_MODAL: "@order/SHOW_OUT_OF_STOCK_ITEMS_MODAL",
  SET_REFRESH_PAGE: "@order/SET_REFRESH_PAGE",
};

const INITIAL_STATE = {
  loading: false,
  message: null,
  data: null,
  checkoutData: null,
  showOrderConfirmation: false,
  showDeliveryStore: false,
  deliveryStoreData: null,
  deliveryStoreDataHour: null,
  orderConfirmationMessage: "",
  customerAcceptOrder: false,
  error: { data: null, visible: false, type: null },
  showOpeningHours: false,
  showDeliverySelectedStore: false,
  refreshPage: false
};

export default function order(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.ORDER_REQUEST: {
        draft.loading = true;
        draft.message = null;
        draft.showDeliveryStore = false;
        draft.customerAcceptOrder = action.payload?.customerAcceptOrder ?? false;
        draft.checkoutData = action.payload.data;
        break;
      }
      case Types.ORDER_FAILURE: {
        draft.loading = false;
        draft.message = action.payload.message;
        draft.data = null;
        draft.checkoutData = null;
        draft.deliveryStoreData = null;
        draft.customerAcceptOrder = false;
        draft.orderConfirmationMessage = "";
        break;
      }
      case Types.ORDER_SUCCESS: {
        draft.loading = false;
        draft.message = null;
        draft.data = action.payload.data;
        draft.showDeliveryStore = false;
        draft.showOpeningHours = false;
        draft.showDeliverySelectedStore = false;
        draft.checkoutData = null;
        draft.deliveryStoreData = null;
        draft.orderConfirmationMessage = "";
        break;
      }
      case Types.ORDER_REQUEST_PIX_PAYMENT: {
        draft.loading = true;
        draft.message = null;
        draft.showDeliveryStore = false;
        draft.checkoutData = action.payload.data;
        draft.refreshPage = false;
        break;
      }
      case Types.ORDER_REQUEST_PIX_PAYMENT_PENDING: {
        draft.loading = false;
        draft.message = null;
        draft.refreshPage = false;
        break;
      }
      case Types.RESET_ERROR_MESSAGE: {
        draft.loading = false;
        draft.message = null;
        break;
      }
      case Types.RESET_DELIVERY_MESSAGE: {
        draft.loading = false;
        break;
      }
      case Types.SHOW_CONFIRM_ORDER_MODAL: {
        draft.loading = false;
        draft.showConfirmOrderModal = true;
        draft.orderConfirmationMessage = action.payload.message;
        break;
      }
      case Types.SHOW_DELIVERY_STORE_MODAL: {
        draft.showDeliveryStore = true;
        draft.loading = false;
        draft.deliveryStoreData = action.payload.data;
        draft.deliveryStoreDataHour = action.payload.deliveryStoreDataHour;
        break;
      }
      case Types.HIDE_DELIVERY_STORE_MODAL: {
        draft.showDeliveryStore = false;
        draft.deliveryStoreData = null;
        draft.deliveryStoreDataHour = null;
        break;
      }
      case Types.HIDE_CONFIRM_ORDER_MODAL: {
        draft.showConfirmOrderModal = false;
        break;
      }
      case Types.CONFIRM_ORDER_REQUEST: {
        draft.loading = true;
        draft.showConfirmOrderModal = false;
        break;
      }
      case Types.ORDER_ERROR: {
        draft.loading = false;
        draft.error = action.payload.data;
        draft.data = action.payload.data;
        draft.showDeliveryStore = false;
        draft.checkoutData = null;
        draft.deliveryStoreData = null;
        draft.orderConfirmationMessage = "";
        break;
      }
      case Types.RESET_ERROR: {
        draft.loading = false;
        draft.error = { data: null, visible: false, type: null };
        draft.showDeliveryStore = false;
        draft.checkoutData = null;
        draft.deliveryStoreData = null;
        draft.orderConfirmationMessage = "";
        draft.message = null;
        break;
      }
      case Types.SHOW_OPENING_HOURS_MODAL: {
        draft.showOpeningHours = true;
        draft.loading = false;
        draft.deliveryStoreData = action.payload.data;
        break;
      }
      case Types.HIDE_OPENING_HOURS_MODAL: {
        draft.showOpeningHours = false;
        draft.deliveryStoreData = null;
        break;
      }
      case Types.SHOW_DELIVERY_SELECTED_STORE_MODAL: {
        draft.showDeliverySelectedStore = true;
        draft.loading = false;
        draft.deliveryStoreData = action.payload.data;
        break;
      }
      case Types.HIDE_DELIVERY_SELECTED_STORE_MODAL: {
        draft.showDeliverySelectedStore = false;
        draft.deliveryStoreData = null;
        break;
      }
      case Types.SHOW_OUT_OF_STOCK_ITEMS_MODAL: {
        draft.error = { data: action.payload.data, visible: true, type: "ERROR_SELECTED_STORE_STOCK_ITEMS" };
        break;
      }
      case Types.SET_REFRESH_PAGE: {
        draft.refreshPage = action.payload.refreshPage;
        break;
      }
      default:
    }
  });
}

export const Creators = {
  orderRequest: (data, customerAcceptOrder, coupon) => ({
    type: Types.ORDER_REQUEST,
    payload: { data, customerAcceptOrder, coupon }
  }),

  orderSuccess: data => ({
    type: Types.ORDER_SUCCESS,
    payload: { data }
  }),

  orderFailure: message => ({
    type: Types.ORDER_FAILURE,
    payload: { message }
  }),

  orderError: data => ({
    type: Types.ORDER_ERROR,
    payload: { data }
  }),

  orderRequestPixPayment: data => ({
    type: Types.ORDER_REQUEST_PIX_PAYMENT,
    payload: { data }
  }),

  orderRequestPixPaymentPending: data => ({
    type: Types.ORDER_REQUEST_PIX_PAYMENT_PENDING,
    payload: { data }
  }),

  resetErrorMessage: () => ({
    type: Types.RESET_ERROR_MESSAGE
  }),

  resetDeliveryMessage: () => ({
    type: Types.RESET_DELIVERY_MESSAGE
  }),

  showConfirmOrderModal: (message) => ({
    type: Types.SHOW_CONFIRM_ORDER_MODAL,
    payload: { message }
  }),

  showDeliveryStoreModal: (data, deliveryStoreDataHour) => ({
    type: Types.SHOW_DELIVERY_STORE_MODAL,
    payload: { data, deliveryStoreDataHour }
  }),

  hideDeliveryStoreModal: () => ({
    type: Types.HIDE_DELIVERY_STORE_MODAL,
  }),

  hideConfirmOrderModal: () => ({
    type: Types.HIDE_CONFIRM_ORDER_MODAL,
  }),


  confirmOrderRequest: (orderConfirmed = false) => ({
    type: Types.CONFIRM_ORDER_REQUEST,
    payload: { orderConfirmed }
  }),

  resetError: () => ({
    type: Types.RESET_ERROR
  }),

  showOpeningHoursModal: (data) => ({
    type: Types.SHOW_OPENING_HOURS_MODAL,
    payload: { data }
  }),

  hideOpeningHoursModal: () => ({
    type: Types.HIDE_OPENING_HOURS_MODAL,
  }),
  showDeliverySelectedStoreModal: (data) => ({
    type: Types.SHOW_DELIVERY_SELECTED_STORE_MODAL,
    payload: { data }
  }),

  hideDeliverySelectedStoreModal: () => ({
    type: Types.HIDE_DELIVERY_SELECTED_STORE_MODAL,
  }),
  showOutOfStockItems: (data) => ({
    type: Types.SHOW_OUT_OF_STOCK_ITEMS_MODAL,
    payload: { data }
  }),
  setRefreshPage: (refreshPage) => ({
    type: Types.SET_REFRESH_PAGE,
    payload: { refreshPage }
  }),
};
