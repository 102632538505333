import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import OrderAgainModal from "../../components/OrderAgainModal";
import { setTitle } from "../../services/browser";
import { Creators as MyOrderActions } from "../../store/ducks/myOrders";
import { Container, LoaderContainer, NoOrders, Wrap } from "./styles";
import CardOrder from "./CardOrder";

export default function MyOrders({ history }) {
  const { data: orders, loading } = useSelector(state => state.myOrders);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const dispatch = useDispatch();

  const closeModal = () => setModalIsOpen(false);

  useEffect(() => {
    setTitle("Meus Pedidos");
    dispatch(MyOrderActions.getRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        <Wrap>
          {loading && orders.length === 0 ? (
            <LoaderContainer>
              <Loader type="ThreeDots" color="#00b2a9" height={40} width={40} />
              <span>Carregando pedidos...</span>
            </LoaderContainer>
          ) : (
            <>
              {orders && orders?.length === 0 && (
                <>
                  <NoOrders>
                    <p>Você ainda não posssui pedidos realizados</p>
                  </NoOrders>
                </>
              )}
              {orders &&
                orders.map(order => (
                  <div key={order.id} className="content">
                    {order.pedido_associado && (
                      <CardOrder order={order.pedido_associado} history={history} actionBuyAgain={() => {
                        setSelectedOrder(order.pedido_associado);
                        setModalIsOpen(!modalIsOpen);
                      }} />
                    )}
                    <CardOrder order={order} history={history} actionBuyAgain={() => {
                      setSelectedOrder(order);
                      setModalIsOpen(!modalIsOpen);
                    }} />
                  </div>

                ))}
            </>
          )}
        </Wrap>
      </Container>
      {modalIsOpen && (
        <OrderAgainModal order={selectedOrder} closeModal={closeModal} />
      )}
    </>
  );
}
