export function telephoneIsValid(telephone) {
    const formattedTelephone = telephone.replace(/[^a-zA-Z0-9]/g, "")
    return formattedTelephone.length === 11 || formattedTelephone.length === 10;
}

export const isValidDate = (dateString) => {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!regex.test(dateString)) return false;

    const [day, month, year] = dateString.split('/').map(Number);
    const date = new Date(year, month - 1, day);


    return date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day ? false : true;
};

export const isAdult = (dateString) => {
    const [day, month, year] = dateString.split('/').map(Number);

    const today = new Date();
    const age = today.getFullYear() - year;
    const monthDiff = today.getMonth() - (month - 1);
    const dayDiff = today.getDate() - day;

    return age > 18 || (age === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)));
};