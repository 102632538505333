import * as Yup from "yup";
import { isAdult, isValidDate, telephoneIsValid } from "../../util/validation";

export const SignUpSchema = Yup.object().shape({
  nome: Yup.string().required("Nome não pode ser vazio"),
  email: Yup.string()
    .email("Informe um e-mail válido")
    .required("E-mail não pode ser vazio"),
  celular: Yup.string().required("Telefone não pode ser vazio").test(
    "celular",
    "Digite um número de telefone válido",
    (celular = "") => {
      return telephoneIsValid(celular);
    }
  ),
  data_nascimento: Yup.string().required(
    "Data de nascimento não pode ser vazio"
  ).test(
    "data_nascimento",
    "Digite uma data de nascimento válida",
    (data_nascimento = "") => {
      return isValidDate(data_nascimento);
    }
  ).test(
    "data_nascimento",
    "Você deve ser maior de 18 anos",
    (data_nascimento = "") => {
      return isAdult(data_nascimento);
    }
  ),
  genero: Yup.string().required("Sexo não pode ser vazio")
});
