import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 355px);
  max-width: 1440px;
  margin: 0 auto;

  > div{
    display: flex;
    flex-direction: column;
    font-size: 25px;
    font-weight: 700;
    color: ${props => props.theme.colors.neutral900};
    > span{
      margin-left: 186px;
      padding: 30px;
      height: 60px;
      margin-top: 50px;
    }

    > div{
      display: flex;
      gap: 38px;
      @media (max-width: 768px){
        flex-direction: column;
        gap: 5px;
      }
    }
  }


`;
export const Content = styled.div`
    width: 100%;
    margin-top: 20px;
    padding-bottom: 24px;
    > span {
      display: flex;

    }

    @media (max-width: 768px) {
      padding-left: 16px;
      padding-right: 16px;
    }
`;

export const Menu = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  border-bottom: 1px solid #ecf0f1;
  margin-bottom: 20px;
  justify-content: center;
  margin-top: 10px;
  width: 180px;
  margin-top: 105px;

  div {
    display: flex;
    align-items: center;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 179px;

    @media (max-width: 767px){
      width: 100%;
    }

    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      padding: 10px 15px;
      width: 100%;

      @media (max-width: 767px){
        flex-direction: column;
        align-items: center;
        a{
          text-align: center;
        }
      }



      img{
        padding-right: 15px;
        @media (max-width: 767px){
          padding-right: 0px;
        }
      }
    }
  }

  @media (max-width: 768px){
    flex-direction: row;
    width: 100%;
    margin-top: 0px;


    > div{
      width: 100%;
    }
    ul{
      display: inline-flex;
      flex-direction: row;

    }
  }

  .active {
    color: ${props => props.theme.colors.primary};
    font-weight: 700;
    transition: 0.3s color;
    border-radius: 4px;
    background: ${props => props.theme.colors.primary40};
  }

  > div {
    display: flex;
    justify-content: space-between;

    button {
      font-size: 1.4rem;
    }

    > span {
      font-size: 2rem;
      font-weight: 500;
      color: ${props => props.theme.colors.primary};
    }
  }
`;
