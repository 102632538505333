import { shade } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 47px;
  width: 100%;
  background-color: #F3F3F4;
  justify-content: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #E8E8E9;
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
  display: flex;
  align-items: center;

  img {
    margin-left: 16px;
  }

  div.no-selected {
    button {
      font-size: 1.4rem;
      padding: 5px 8px;
      border-radius: 50px;
      font-weight: 500;
      color: #143C97;
      background: transparent;
      border: none;
      font-weight: 700;
    }
  }

  div.selected {
    display: flex;
    flex-direction: row;
    padding: 8px;
    align-items: center;
    font-size: 14px;

    button {
      font-size: 1rem;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      padding-left: 5px;
      border-radius: 50px;
      background: none;
      border: none;
      color: ${props => props.theme.colors.bodyLight};
      transition: background 0.3s;

      :hover {
        background: ${shade(0.05, "#f9f9f9")};
      }

      /* svg {
        font-size: 1.5rem;
      } */
    }

    span {
      color: #143C97;
      border: none;
      font-weight: 700;
      cursor: pointer;
      margin-top: 3px;

      /* color: var(--neutral-900, #151922);

      font-family: Red Hat Display;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26.5px; */
    }
  }
`;
