import React, { useEffect } from "react";
import { Creators as StoreByUserActions } from "../../store/ducks/storeByUser";
import { Creators as UserActions } from "../../store/ducks/user";
import { Creators as CartActions } from "../../store/ducks/cart";
import { Creators as DomainActions } from "../../store/ducks/domain";
import { useDispatch, useSelector } from "react-redux";
import version from "../../config/version";
import store from "../../store";
import { setTitle } from "../../services/browser";

export default function OnInit() {
  const dispatch = useDispatch();
  const allowedDomain = useSelector(state => state.domain.allowedDomain);
  const signed = store.getState().auth.signed;

  useEffect(() => {
    dispatch(DomainActions.getDomainRequest());
    if(signed){
      dispatch(UserActions.getAccountRequest());
    }      
    dispatch(CartActions.getStorage());
    dispatch(CartActions.calculateAmount());

    const storageVersion = localStorage.getItem("@ConexaoGAM:version") || null;

    if (storageVersion !== version.basketVersion) {
      localStorage.removeItem("@ConexaoGAM:cart");
      localStorage.removeItem("@ConexaoGAM:checkout");
      dispatch(CartActions.resetDefault());
      localStorage.setItem("@ConexaoGAM:version", version.basketVersion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allowedDomain) {
      dispatch(StoreByUserActions.checkItemsOutOfStock());
    }

  setTitle(null, true);

  }, [allowedDomain]) //eslint-disable-line

  return <></>;
}
