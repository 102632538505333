import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import CardSale from "../CardSale";
import Pagination from "../Pagination";
import {
  Container,
  ContainerPage,
  ContainerProductFound,
  ContainerProductList,
  ContainerProducts,
  LoaderContainer,
  NoResultsFound
} from "./styles";
import Loader from "react-loader-spinner";

export default function ItemList({ products, loading, loadMore, haveFilter, searchTerm = '', isMobile, totalizadorGeral }) {
  const store = useSelector(state => state.storeByUser.data);
  const { limit } = useSelector(
    state => state.search
  );

  useEffect(() => {
    if (window.screen.height <= 600) {
      const text = document.createElement('h1')

      document.body.appendChild(text)
    }
  }, [])

  return (
    <>
      <Container>
        <ContainerProducts>
          <ContainerProductList id="featured-products">
            {products.length <= 0 && loading && (
              <LoaderContainer>
                <Loader type="Oval" color="#00786A" height={36} width={36} />
              </LoaderContainer>
            )}
            {(products && products.length === 0 && !loading) && (
              <NoResultsFound>
                <span>
                  <b>Ah não :</b> Não encontramos o produto que você pesquisou
                </span>
              </NoResultsFound>
            )}
            {products &&
              products.map((product) => (
                <CardSale key={product.id} product={product} hasSelectedStore={store !== null} />
              ))}
          </ContainerProductList>
          <ContainerPage>
            {totalizadorGeral > 0 && (
              <>
                {!loading && isMobile && (
                  <ContainerProductFound>
                    <span>{totalizadorGeral} produtos encontrados</span>
                  </ContainerProductFound>
                )}
                <Pagination show={!loading} totalItens={totalizadorGeral} itensByPage={limit} loadMore={loadMore} />
              </>
            )}
          </ContainerPage>
        </ContainerProducts>
      </Container>
    </>
  );
}
