import React from "react";
import ButtonPrimary from "../ButtonPrimary";
import ButtonSecundary from "../ButtonSecundary";
import imgFechar from "../../assets/images/close-popup.svg";
import { Container, Modal, Header, Content, Footer } from "./styles"

const ModalLayout = ({ showModal, title, handleCloseModal, children, buttonLeft, buttonRight, }) => {
    return (
        <>
            {showModal && (
                <Container>
                    <Modal>
                        <Header>
                            <button onClick={handleCloseModal}>
                                <img src={imgFechar} alt={"Fechar"} />
                            </button>
                            <h1>{title}</h1>
                        </Header>
                        <Content>
                            {children}
                        </Content>
                        <Footer>
                            <ButtonPrimary text={buttonLeft.text} onClick={buttonLeft.action} />
                            <ButtonSecundary text={buttonRight.text} onClick={buttonRight.action} />
                        </Footer>
                    </Modal>
                </Container>
            )}
        </>

    )
}

export default ModalLayout;