import styled from "styled-components";

export const Container = styled.div`
  .step-row {
    background-color: transparent;
    margin-top: 15px;
  }

  .step-connector {
    height: 3px;
    background-color: ${props => props.theme.colors.gray};
    color : ${props => props.theme.colors.gray}; 
    border-top-style: none;

      &.complete-active {
        background-color: ${props => props.theme.colors.alertsuccess};
        color: ${props => props.theme.colors.alertsuccess};
      }
  }
`;