import styled from "styled-components";
import { device } from "../../styles/device";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  position: fixed;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 6000 !important;
  overflow-y: auto;
  top: 0;
`;

export const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 16px 16px 24px;
  background: #fff;
  border-radius: 16px;

  @media ${device.laptop}{
    padding: 24px 56px 24px 56px;
}
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    h1 {
        font-size: 20px;
        font-weight: 700;
        color: #151922;
    }

    button {
        align-self: flex-end;
        display: flex;
        align-items: center;
        background-color: #fff;
        border: none;
    }
`;

export const Content = styled.div`
    margin-bottom: 47px;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media ${device.laptop}{
        flex-direction: row;
    }
`;