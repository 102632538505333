import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Switch, useRouteMatch } from "react-router-dom";
import account from "../../assets/images/account.svg";
import accountOn from "../../assets/images/accountOn.svg";
import address from "../../assets/images/address.svg";
import addressOn from "../../assets/images/addressOn.svg";
import orders from "../../assets/images/orders.svg";
import ordersOn from "../../assets/images/ordersOn.svg";
import Account from "../../pages/Account";
import Addresses from "../../pages/Addresses";
import MyOrders from "../../pages/MyOrders";
import OrderDetails from "../../pages/OrderDetails";
import Panel from "../../pages/Panel";
import RewardsProgramAcceptance from "../../pages/RewardsProgramAcceptance";
import Route from "../../routes/Route";
import MainLayout from "../MainLayout";
import { Container, Content, Menu } from "./styles";

export default function AccountMenu({ history }) {
  let [headerTitle, setHeaderTitle] = useState("PAINEL");
  let { url, path } = useRouteMatch();

  const { programa_fidelidade_nome } = useSelector((state) =>
    state.store.data?.empresa ?? "Programa de Fidelidade"
  );

  const uri = window.location.pathname;

  useEffect(() => {
    if (uri.includes("pedido")) {
      if (uri.includes("detalhes-pedido")) {

        let tit = document.title.substring(0, document.title.indexOf('|') - 1);

        if (tit !== '' && tit !== 'Aguardando Pagamento')
            setHeaderTitle(tit);
        else {
          tit = uri.substring(uri.lastIndexOf('/') + 1)

          if(tit  !== '')
             setHeaderTitle('Pedido #' + tit);
        }
      }
      else {
        setHeaderTitle("Meus pedidos");
      }
    } else if (uri.includes("dados")) {
      setHeaderTitle("");
    } else if (uri.includes("enderecos")) {
      setHeaderTitle("Meus endereços");
    } else if (uri.includes("novo-endereco")) {
      setHeaderTitle("Novo endereço");
    } else if (uri.includes("alterar-senha")) {
      setHeaderTitle("Alterar senha");
    } else if (uri.includes("programa-fidelidade")) {
      setHeaderTitle(programa_fidelidade_nome);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri]);

  return (
    <MainLayout history={history}>
      <Container>
        <div>
          <div>
            <Menu>
              <ul>
                <li
                  onClick={() => {
                    // setHeaderTitle("Minha conta");
                    // history.push(`${url}/meus-dados`);
                  }}
                  className={
                    window.location.pathname === "/painel/meus-dados"
                      ? `active`
                      : null
                  }
                >
                  <img src={window.location.pathname === "/painel/meus-dados" ? accountOn : account} alt={"Icon Account"} />
                  <Link to={`${url}/meus-dados`}>Minha Conta</Link>
                </li>
                <li
                  onClick={() => {
                    // setHeaderTitle("Pedidos");
                    // history.push(`${url}/meus-pedidos`);
                  }}
                  className={
                    window.location.pathname.includes("pedido") ? `active` : null
                  }
                >
                  <img src={window.location.pathname.includes("pedido") ? ordersOn : orders} alt={"Icon Orders"} />
                  <Link to={`${url}/meus-pedidos`}>Pedidos</Link>
                </li>
                <li
                  onClick={() => {
                    // setHeaderTitle("Endereços");
                    // history.push(`${url}/enderecos`);
                  }}
                  className={
                    window.location.pathname.includes("endereco") ? `active` : null
                  }
                >
                  <img src={window.location.pathname.includes("endereco") ? addressOn : address} alt={"Icon Address"} />
                  <Link to={`${url}/enderecos`}>Endereços</Link>
                </li>
              </ul>
            </Menu>
            <Content className={uri.includes("detalhes-pedido") ? "withoutMargin" : ""}>
              <span className={uri.includes("detalhes-pedido") ? "withSubtitles" : ""}>{headerTitle}</span>
              <Switch>
                <Route isPrivate exact path={path}></Route>
                <Route isPrivate path={`${path}/meus-dados`} component={Panel} />
                <Route isPrivate path={`${path}/editar-dados`} component={Account} />
                <Route
                  exact
                  isPrivate
                  path={`${path}/enderecos`}
                  component={Addresses}
                />
                <Route
                  isPrivate
                  exact
                  path={`${path}/meus-pedidos`}
                  component={MyOrders}
                />
                <Route
                  isPrivate
                  path={`${path}/detalhes-pedido/:id`}
                  component={OrderDetails}
                />
                <Route
                  isPrivate
                  path={`${path}/programa-fidelidade`}
                  component={RewardsProgramAcceptance}
                />
              </Switch>
            </Content>
          </div>
        </div>
      </Container>
    </MainLayout>
  );
}
