import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    text-align: start;
  }
`;

export const PickupTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  h3 {
    font-size: 18px;
    line-height: 21.17px;
    color: #151922;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 500;
`;

export const DateParagraph = styled(Paragraph)`
  color: #000000;
`;

export const HourParagraph = styled(Paragraph)`
  color: #143C97;
`;