import styled from "styled-components";

export const Header = styled.div`
  min-height: 80px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    max-width: 1440px;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 16px;

    @media (max-width: 585px) {
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
    }

    #ordenationContainer {
      display: flex;
      flex-direction: row;
    }
  }
`;

export const Info = styled.div`
  display: flex;

  svg {
    margin-right: 10px;
    fill: ${props => props.theme.colors.primary};
    margin-top: 10px;

    @media (max-width: 350px) {
      display: none;
    }
  }

  > span {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    strong {
      color: ${props => props.theme.colors.black};
      font-size: 25px;
      font-weight: 700;
    }

    > span {
      font-size: 1.6rem;
      color: ${props => props.theme.colors.bodyLight};
    }
  }
`;

export const HistoryBack = styled.button`
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  color: ${props => props.theme.colors.bodyLight};
  cursor: pointer;
  text-transform: uppercase;

  svg {
    margin-right: 5px;
    font-size: 1.6rem;
  }

  @media (max-width: 585px) {
    font-size: 1.4rem;
    padding: 10px 0;
    font-weight: 500;
  }
`;
