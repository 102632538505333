import styled from "styled-components";
import { darken } from "polished";

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  > div {
    font-weight: 700;
    color: ${props => props.theme.colors.neutral900};
  }
`;

export const Label = styled.label`
  transition: all 0.3s ease;
  font-family: 'Red Hat Display', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: ${props => props.theme.colors.neutral900};
`;

export const RadioGroup = styled.div.attrs(props => ({
  style: {
    flexDirection: props.rowDirection ? "row" : "column",
  },
}))`
  display: flex;
  font-size: 1.6rem;

  .radio {
    margin: 0.5rem;
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        font-family: "Red Hat Display";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: ${props =>
        props.invalid
          ? props.theme.colors.warning
          : props.theme.colors.neutral900};
        &:before {
          content: "";
          border-radius: 100%;
          border: 1px solid ${props => props.theme.colors.neutral400};
          display: inline-block;
          width: 1.4em;
          height: 1.4em;
          position: relative;
          top: -0.2em;
          margin-right: 1em;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          color: ${props => props.theme.colors.primary};
          &:before {
            border: 1px solid ${props => props.theme.colors.primary};
            background-color: ${props => props.theme.colors.primary};
            box-shadow: inset 0 0 0 4px #f4f4f4;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: ${props => props.theme.colors.primary};
          }
        }
      }
      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px #f4f4f4;
            border-color: ${darken(0.25, "#f4f4f4")};
            background: ${darken(0.25, "#f4f4f4")};
          }
        }
      }
      &:hover {
        + .radio-label {
          color: ${props => props.theme.colors.primary};
          &:before {
            border-color: ${props => props.theme.colors.primary};
          }
        }
      }
      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }
`;

export const FieldErrorMessage = styled.div`
  color: ${props => props.theme.colors.warning};
  font-size: 14px;
  margin-top: 5px;
  font-family: 'Red Hat Display', sans-serif;;
`;