import React from "react";
import MaskedInput from "react-text-mask";
import { FieldErrorMessage, FormGroup, Input, Label } from "./styles";

const MaskedInputCustom = ({ id, name, description, value, onChange, onBlur, isInvalid, errors, mask, className }) => {
    return (
        <FormGroup className={className}>
            {isInvalid ? (
                <FieldErrorMessage>{errors}</FieldErrorMessage>
            ) : null}
            <MaskedInput
                mask={mask}
                invalid={isInvalid}
                id={id}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                render={(ref, props) => (
                    <Input ref={input => ref(input)} {...props} />
                )}
            />
            <Label htmlFor={id}>{description}</Label>
        </FormGroup>
    );
};

export default MaskedInputCustom;