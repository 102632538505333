import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    background-color: #FDF7E0;
    padding: 24px;
    border-radius: 8px;
    align-items: center;

    img{
        height: 32px;
        width: 32px;
    }
`;

export const ContainerDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;

    h1, p{
        font-size: 14px;
    }

    h1{
        font-weight: 700;
    }
`;