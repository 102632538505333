import React, { useEffect, useState } from "react";
import { setTitle } from "../../services/browser";
import {
  Container,
  Content,
  KeepBuying,
  LoaderContainer,
  ImagePix,
} from "./styles";
import BuyStepsTemplate from "../../components/BuyStepsTemplate";
import { useDispatch, useSelector } from "react-redux";
import { Creators as MyOrderActions } from "../../store/ducks/myOrders";
import { Creators as OrderAction } from "../../store/ducks/order";
import copyToClipboard from "../../assets/images/copyToClipboard.svg";
import ModalConfirm from "../../components/ModalConfirm";
import Loader from "react-loader-spinner";

export default function PaymentPending({ history }) {
  const order = useSelector((state) => state.myOrders.orderSelected || null);
  const refreshPage = useSelector((state) => state.order.refreshPage);
  const [pixCode, setPixCode] = useState("");
  const [urlPixCode, setUrlPixCode] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [blurPix, setBlurPix] = useState(true);
  const [startTimer, setStartTimer] = useState(false);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [orderChecked, setOrderChecked] = useState(false);
  const [msgModalAlert, setMsgModalAlert] = useState("");
  const [statusPedido, setStatusPedido] = useState("");

  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    setTitle("Aguardando Pagamento");
    VerificarStatusPedido();
  }, []);

  useEffect(() => {
    if (refreshPage) {
      VerificarStatusPedido();
    }
  }, [refreshPage]);

  useEffect(() => {
    if (startTimer) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);

      const statusChecker = setInterval(() => {
        VerificarStatusPedido();
      }, 5000);

      return () => {
        clearInterval(timer);
        clearInterval(statusChecker);
      };
    }
  }, [startTimer]);

  useEffect(() => {
    if (timeLeft === 0) {
      VerificarStatusPedido();
    }
  }, [timeLeft]);

  useEffect(() => {
    if (orderChecked) {
      if (order && order?.status !== undefined) setStatusPedido(order?.status);

      if (
        order &&
        order?.status !== undefined &&
        order?.status !== "AP" &&
        order?.status !== "FP"
      ) {
        dispatch(MyOrderActions.setOrderSelected(order));
        history.push("/painel/detalhes-pedido/" + order.id);
      } else if (
        order.pagamento.pix_data_expiracao === null &&
        order?.status !== "FP"
      ) {
        RequestPixPayment();
      } else if (
        order &&
        order?.status !== undefined &&
        order?.status === "AP"
      ) {
        const timeDifference = calculateTimeDifference(
          order.pagamento.pix_data_expiracao
        );

        setPixCode(order.pagamento.pix_code);
        setUrlPixCode(order.pagamento.pix_url_qrcode);

        if (timeDifference > 0) {
          setShowModalAlert(false);
          setTimeLeft(timeDifference);
          setStartTimer(true);
          setBlurPix(false);
        } else {
          setMsgModalAlert(
            "O pagamento esgotou o tempo limite para ser pago, tente pagar dentro do tempo exibido na geração do pagamento via Pix. <br><br>Clique no botão ao final da página para gerar um novo pagamento."
          );
          setShowModalAlert(true);
          setBlurPix(true);
        }
        setShowLoading(false);
      } else if (
        order &&
        order?.status !== undefined &&
        order?.status === "FP"
      ) {
        setMsgModalAlert(
          "Houve uma falha na geração do pagamento via Pix. <br><br>Clique no botão ao final da página para tentar novamente."
        );
        setShowModalAlert(true);
        setShowLoading(false);
      }
    }
  }, [order]);

  const RequestPixPayment = () => {
    dispatch(OrderAction.orderRequestPixPayment(order?.id));
    setShowModalAlert(false);
    setShowLoading(true);
  };

  const VerificarStatusPedido = () => {
    dispatch(MyOrderActions.getOrderById(order?.id));
    setOrderChecked(true);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  function calculateTimeDifference(expirationDate) {
    const now = new Date();
    const expiration = new Date(expirationDate);

    const differenceInMillis = expiration - now;

    if (differenceInMillis > 0) {
      const differenceInSeconds = Math.floor(differenceInMillis / 1000);
      return differenceInSeconds;
    } else {
      return 0;
    }
  }

  const copyToClipboardAction = () => {
    const textToCopy = document.querySelector(
      ".span-codigoPix-hidden"
    ).textContent;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("Código copiado para a área de transferência");
      })
      .catch((err) => {
        console.error("Erro ao copiar texto: ", err);
      });
  };

  const handleClickGerarPagamento = (event) => {
    event.preventDefault();
    RequestPixPayment();
  };

  return showLoading ? (
    <div>
      <LoaderContainer>
        <Loader type="ThreeDots" color="#00b2a9" height={40} width={40} />
        <span>Carregando pedido...</span>
      </LoaderContainer>
    </div>
  ) : (
    <BuyStepsTemplate step={4} history={history}>
      <ModalConfirm
        message={msgModalAlert}
        visible={showModalAlert}
        textBtnCancel="Fechar"
        cancel={() => {
          setShowModalAlert(false);
          setMsgModalAlert("");
        }}
      />

      <Container>
        <Content>
          <div className="header">
            {blurPix && (
              <span className="title-exp">
                {statusPedido === "FP"
                  ? "Falha no pagamento"
                  : "Pagamento não efetuado"}
              </span>
            )}
            {!blurPix && <span className="title">Pagamento</span>}
          </div>
          <span className="successText">
            Nº do pedido: <strong>#{order.id}</strong>
          </span>
          <span className="successText">
            Para finalizar seu pedido, efetue o pagamento através do QrCode
            <p />
            abaixo ou copie e cole o código Pix.
          </span>
          <br />
          {!blurPix && (
            <span className="span-link">
              Pagar em até: <strong>{formatTime(timeLeft)} segundos</strong>
            </span>
          )}
          <br />
          {urlPixCode && (
            <ImagePix
              blurPix={blurPix}
              src={urlPixCode}
              alt={{ urlPixCode }}
              style={{
                height: 250,
                width: 250,
              }}
            />
          )}

          <br />
          {!blurPix && (
            <div>
              <span className="span-codigoPix">{pixCode}</span>
              <span className="span-codigoPix-hidden">{pixCode}</span>
              <br />
              <div className="div-copyClip">
                <img
                  src={copyToClipboard}
                  onClick={copyToClipboardAction}
                  width={25}
                  alt="CopiarCodigo"
                  title={"Copiar código"}
                ></img>
                <span className="span-copiar" onClick={copyToClipboardAction}>
                  Copiar código
                </span>
              </div>
              <br />
            </div>
          )}

          <div className="footer">
            <span className="title">Instruções para pagamento:</span>
          </div>

          <div className="container-instructions">
            <span>1 - Acesso o app do seu banco ou internet banking</span>
            <span>2 - Escolha pagar por Pix</span>
            <span>
              3 - Escaneie o Qr Code ou copie e cole o código Pix acima
            </span>
            <span>4 - Seu pagamento será aprovado em poucos instantes</span>
          </div>

          <div className="actions">
            <KeepBuying havebg={"false"} to="/painel/meus-pedidos">
              <span>Ver meus pedidos</span>
            </KeepBuying>
            {blurPix && (
              <KeepBuying
                to="#"
                havebg={"true"}
                onClick={handleClickGerarPagamento}
              >
                <span>Gerar pagamento</span>
              </KeepBuying>
            )}
          </div>
        </Content>
      </Container>
    </BuyStepsTemplate>
  );
}
