import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  ItemDetail,
  Items,
  OrderItems,
  PurchaseSummary,
  Status,
  Wrap
} from "./styles";

import { format } from 'date-fns';
import pt from "date-fns/locale/pt";
import { setTitle } from "../../services/browser";
import { formatPrice } from "../../util/format";
import { formatCPF } from '../../util/formatCPF';
import { formatPhone } from '../../util/formatPhone';
import { FormaPagamento, TipoPagamento, obterDescricaoFormaPagamento } from "../../util/payment";
import { DeliveryType } from "../../util/deliveryType";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import OrderTimeLine from "./OrderTimeLine";

export default function OrderDetails({ history }) {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const profile = useSelector(state => state.user.profile)
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    fetchOrderById(id, profile.uuid)
  }, [id, profile])

  const fetchOrderById = async (orderId, userUuid) => {
    try {
      setShowLoading(true)
      const { data } = await api.get(`orders/${userUuid}/${orderId}`)
      setOrder(data)
      setTitle(`Pedido #${data.id}`);
    } catch (error) {
      const messageError = error.data && error.data.user ? error.data.user : 'Erro ao buscar pedido'
      toast.error(messageError)
      history.push("/painel/meus-pedidos");
    } finally {
      setShowLoading(false)
    }
  }

  if (showLoading) {
    return (
      <Container>
        <div className="loading">
          <Loader type="ThreeDots" color="#00b2a9" height={40} width={40} />
        </div>
      </Container>
    )
  } else if (order !== null) {
    return (
      <>
        <Container>
          <div className="subtitleArea">
          <div className="timeLineOverFlow" >
              <OrderTimeLine order={order} />
          </div>
          <div className="subtitleAreaFlex">
              <div className="subtitle">
                <span>Feito em</span>
                <span className="bold">
                  {" "}
                  {format(
                    new Date(order?.data_criacao),
                    "dd/MM/yyyy",
                    { locale: pt })}
                </span>
              </div>
            <Status style={{flex:1, display:"flex", justifyContent: "end"}}>
              <span className={order?.status}> {order?.status_cliente}</span>
            </Status>
          </div>
          </div>
          <Wrap>
            <OrderItems>
              <div>
                <ItemDetail>
                  <h1>Itens do pedido</h1>
                  {order?.itens.map(item => (
                    <Items key={item.id}>
                      <img
                        alt={item?.produto.nome || "Item"}
                        title={item?.produto.nome}
                        src={item?.produto.imagens[0].caminho_imagem}
                      />

                      <div>
                        <strong>{item?.produto.nome}</strong>
                        <div>
                          <span>{item?.quantidade}x</span>
                          <span>{formatPrice(item?.preco)}</span>
                          <span className="rightSide">{formatPrice(item?.preco * item?.quantidade)}</span>
                        </div>
                      </div>
                    </Items>
                  ))}
                </ItemDetail>
              </div>
            </OrderItems>
            <PurchaseSummary>
              {order.tipo_entrega === DeliveryType.RECEBER_EM_CASA && (
                <>
                  <div className="order-address">
                    <h3>Endereço de entrega</h3>

                    <p>
                      {order?.endereco_entrega?.endereco},{" "}
                      {order?.endereco_entrega?.numero}
                      {order?.endereco_entrega?.complemento
                        ? `- ${order?.endereco_entrega?.complemento}`
                        : ""}
                    </p>
                    <p>
                      {order?.endereco_entrega?.bairro} -{" "}
                      {order?.endereco_entrega?.cidade.nome} -{" "}
                      {order?.endereco_entrega?.cidade.uf_sigla}
                    </p>
                    <p>CEP: {order?.endereco_entrega?.cep}</p>
                  </div>

                  <div className="order-address">
                    <h3>FARMÁCIA SELECIONADA PARA ENTREGA</h3>

                    <p className="capitalize">
                      {`${order.loja.nome_fantasia}, ${order.loja.cidade.toLowerCase()} - ${order.loja.uf}`}
                    </p>

                    <p className="capitalize">
                      {`${order.loja.endereco}`}
                    </p>

                    <p className="capitalize">
                      {`${formatPhone(order.loja.telefone)}`}
                    </p>
                  </div>
                </>
              )}

              {order.tipo_entrega === "RETIRAR_NA_LOJA" && (
                <div className="order-address">
                  <h3>Farmácia para retirada</h3>

                  <p className="capitalize">
                    {`${order.loja.nome_fantasia.toLowerCase()} - ${order.loja.cidade.toLowerCase()}/${order.loja.uf}`}
                  </p>

                  <p className="capitalize">
                    {`${order.loja.endereco.toLowerCase()}, ${order.loja.bairro.toLowerCase()} - ${order.loja.cep.replace(/(\d{5})(\d{3})/g, "$1-$2")}`}
                  </p>

                  <p className="capitalize">
                    {`${order.loja.telefone.replace(/(\d{2})(\d{4})(\d{4})/g, "($1) $2-$3")}`}
                  </p>
                </div>
              )}

              {
                order.data_prevista && (
                  <div className="expected-time">
                    <h3>Previsão de {order.tipo_entrega === DeliveryType.RETIRAR_NA_LOJA ? "retirada" : "entrega"}</h3>
                    <p>
                      <span className="bold">{format(new Date(order.data_prevista), "dd/MM/yyyy", { locale: pt })}</span>
                      &nbsp;à partir das&nbsp;
                      <span className="bold">{format(new Date(order.data_prevista), "HH:mm", { locale: pt })}</span>
                    </p>
                  </div>
                )
              }

              {order.tipo_entrega === DeliveryType.RETIRAR_NA_LOJA && order.cpf_retirada_loja !== null && (
                <div>

                  <div className="order-third-retreat">
                    <h3>Retirada por terceiro</h3>
                    <p>
                      <span className="bold">
                        {order.nome_retirada_loja} - {formatCPF(order.cpf_retirada_loja)}
                      </span>
                    </p>
                    <p>
                      <span className="bold">
                        {formatPhone(order.telefone_retirada_loja)}
                      </span>
                    </p>
                  </div>
                </div>
              )}


              {order.pagamento && order.tipo_entrega && (
                <>
                  <div className="order-payment">
                    <h3>{order.pagamento.tipo_pagamento === TipoPagamento.Online ? "Pagamento online" : order.tipo_entrega === DeliveryType.RETIRAR_NA_LOJA ? "Pagamento na retirada" : "Pagamento na entrega"}</h3>
                    <p>
                      <span>{obterDescricaoFormaPagamento(order.pagamento.forma_pagamento)}</span>
                      <span className="bold">
                        {formatPrice(parseFloat(order?.pagamento.valor_total))}{" "}
                        {order.pagamento.forma_pagamento === FormaPagamento.CartaoCredito && (
                          <>
                            <br /> <span className="small no-bold">(À vista)</span>
                          </>
                        )}

                      </span>
                    </p>
                    {order.pagamento.forma_pagamento === FormaPagamento.Dinheiro && (
                      <>
                        <p>
                          <span>Troco</span>
                          <span className="bold">
                            {formatPrice(parseFloat(order.pagamento.valor_troco))}
                          </span>
                        </p>
                        <p>
                          <span>Valor do Pagamento</span>
                          <span className="bold">
                            {formatPrice(parseFloat(order.pagamento.valor_pagamento))}
                          </span>
                        </p>
                      </>
                    )}
                    {order.pagamento.forma_pagamento === FormaPagamento.Convenio && (
                      <p>
                        <span>Empresa</span>
                        <span className="bold">
                          {order.pagamento.empresa_convenio}
                        </span>
                      </p>
                    )}
                    {(order.pagamento.forma_pagamento === FormaPagamento.Convenio || order.pagamento.forma_pagamento === FormaPagamento.Crediario) && (
                      <>
                        <p>
                          <span>Nome</span>
                          <span className="bold">
                            {order.pagamento.nome_pessoa}
                          </span>
                        </p>
                        <p>
                          <span>Telefone</span>
                          <span className="bold">
                            {order.pagamento.telefone_pessoa}
                          </span>
                        </p>
                      </>
                    )}
                    {order.pagamento.forma_pagamento === FormaPagamento.CartaoCredito && (
                      <p>
                        <span>Número de parcelas</span>
                        <span className="bold">
                          {order?.pagamento.parcelas ? order?.pagamento.parcelas : 1}{`x de ${formatPrice(parseFloat(order?.pagamento.valor_total) / order?.pagamento.parcelas)}`}
                        </span>
                      </p>
                    )}
                  </div>
                </>
              )}

              <div className="order-subtotals">
                <h3>Total da compra</h3>
                <p>
                  <span>Subtotal</span>
                  <span className="bold">
                    {formatPrice(order?.pagamento.valor_subtotal)}
                  </span>
                </p>

                <p>
                  <span>Taxa de entrega</span>
                  <span className="bold">
                    {formatPrice(order?.pagamento.custo_entrega)}
                  </span>
                </p>
                {order?.pagamento.valor_desconto_total > 0 && (
                  <p>
                    <span>Você economizou</span>
                    <span className="bold">
                      {formatPrice(order?.pagamento.valor_desconto_total)}
                    </span>
                  </p>
                )}

                <p>
                  <span className="bold">Valor total</span>
                  <span className="bold">
                    {formatPrice(parseFloat(order?.pagamento.valor_total))}
                  </span>
                </p>
              </div>
            </PurchaseSummary>
          </Wrap>
        </Container>
      </>
    );
  }

  return <></>;
}
