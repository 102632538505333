import { call, put, select } from "redux-saga/effects";
import api from "../../services/api";
import history from "../../services/history";
import { Creators as CartActions } from "../ducks/cart";
import { Creators as OrderActions } from "../ducks/order";
import { Creators as MyOrderActions } from "../ducks/myOrders";
import { Creators as ProductsActions } from "../ducks/products";
import { getCartFromStorage } from "./cart";
import { DeliveryType } from "../../util/deliveryType";

export function* setQuantityProducts(cartProducts) {
  const { data: stateProducts } = yield select((state) => state.products);

  const products = stateProducts.map((stateProduct) => {
    return {
      ...stateProduct,
      quantidade: 0,
    };
  });

  yield put(ProductsActions.setDataSuccess(products));
}

export function* completeOrder(payload) {
  const deliveryStore = yield select(
    (state) => state.order.deliveryStoreData || null
  );
  const carrinho = yield getCartFromStorage();
  const { profile } = yield select((state) => state.user);
  const paymentData = yield select((state) => state.order.checkoutData);
  const { showDeliverySelectedStore } = yield select((state) => state.order);
  const { coupon } = yield select(state => state.coupon);

  let finalBasket = [];

  carrinho.forEach((product) => {
    const productFound = finalBasket.find((item) => item.id === product.id);

    if (!productFound) {
      finalBasket.push({
        id: product.id,
        nome: product.nome,
        quantidade: product.quantidade + (product.brotherQuantity ?? 0),
      });
    }
  });

  let storeByUser = yield select((state) => state.storeByUser.data);
  const amount = yield select((state) => state.cart.amount);
  const { address, dadosEntrega } = yield JSON.parse(
    localStorage.getItem("@ConexaoGAM:checkout")
  );
  let cartaoCredito, dinheiro, convenio, crediario;

  if (paymentData.paymentPlace == "O") {
    if (paymentData.paymentMode == "CR") {
      const expiry = paymentData.expiry.split("/");
      cartaoCredito = {
        numeroCartao: paymentData.number.replace(/ /g, ""),
        codigoSeguranca: paymentData.cvc,
        mesExpiracao: expiry[0],
        anoExpiracao: "20" + expiry[1],
        nomeTitular: paymentData.name,
        parcelas: paymentData.installments,
      };
    }
  } else {
    if (paymentData.paymentMode == "DI") {
      dinheiro = {
        valor_pagamento: parseFloat(
          paymentData.paymentValue.replace("R$", "").replace(",", ".")
        ),
      };
    } else if (paymentData.paymentMode == "CV") {
      convenio = {
        empresa: paymentData.companyName,
        nome: paymentData.clientNameCV,
        telefone: paymentData.phoneCV,
      };
    } else if (paymentData.paymentMode == "CE") {
      crediario = {
        nome: paymentData.clientNameCE,
        telefone: paymentData.phoneCE,
      };
    }
  }

  let couponName = '';
  if(coupon !== null && coupon.data !== null) {
    couponName = coupon.data.nome;
  }

  let checkoutData = {
    lojaEntregaEnderecoCliente: true,
    clienteUuid: profile.uuid,
    enderecoId: address ? address.id : null,
    dadosEntrega,
    token: "ak_test_bwxmysOsvvYNQqPJVcW4I8MoHj0ZV0",
    clienteAceitouEntrega: true,
    pagamento: {
      tipoPagamento: paymentData.paymentPlace,
      formaPagamento: paymentData.paymentMode,
      valorTroco: 0,
      valorTotal: amount,
      cartao: cartaoCredito,
      dinheiro: dinheiro,
      convenio: convenio,
      crediario: crediario,
      perc_desconto: paymentData.paymentPercDiscount,
    },
    carrinho: finalBasket,
    cupom: couponName
  }

  if (storeByUser !== null) {
    checkoutData = { ...checkoutData, lojaUuid: storeByUser.uuid };

    const checkStoreByUser = yield call(
      api.get,
      `/stores/${storeByUser.cnpj}/${dadosEntrega.tipoEntrega}`
    );

    storeByUser = checkStoreByUser.data;

    const currentDate = new Date();
    const startTimeStore = new Date(
      `${storeByUser.lojaHorario.data_disponibilidade} ${storeByUser.lojaHorario.hora_inicio}`
    );
    const endTimeStore = new Date(
      `${storeByUser.lojaHorario.data_disponibilidade} ${storeByUser.lojaHorario.hora_fim}`
    );
    const isOpenNow =
      currentDate > startTimeStore && currentDate < endTimeStore;

    if (
      dadosEntrega.tipoEntrega === DeliveryType.RECEBER_EM_CASA &&
      showDeliverySelectedStore === false &&
      !isOpenNow
    ) {
      yield put(OrderActions.showDeliverySelectedStoreModal(storeByUser));
      return;
    }
  }

  if (deliveryStore !== null) {
    checkoutData = {
      ...checkoutData,
      lojaUuid: deliveryStore.uuid,
      dadosEntrega: { ...dadosEntrega, lojaSelecionada: deliveryStore },
    };
  }

  if (checkoutData.dadosEntrega && checkoutData.dadosEntrega.dadosTerceiro) {
    if (checkoutData.dadosEntrega.dadosTerceiro.cpf)
      checkoutData.dadosEntrega.dadosTerceiro.cpf =
        checkoutData.dadosEntrega.dadosTerceiro.cpf.replace(/[^\d]+/g, "");
    if (checkoutData.dadosEntrega.dadosTerceiro.telefone)
      checkoutData.dadosEntrega.dadosTerceiro.telefone =
        checkoutData.dadosEntrega.dadosTerceiro.telefone.replace(/[^\d]+/g, "");
  }

  const orderCheckoutResponse = yield call(
    api.post,
    `/orders/checkout`,
    checkoutData
  );

  if (orderCheckoutResponse.status === 202) {
    yield put(
      OrderActions.showDeliveryStoreModal(
        orderCheckoutResponse.data.store,
        orderCheckoutResponse.data.storeIsOpen
      )
    );
    return;
  }

  yield put(OrderActions.orderSuccess(orderCheckoutResponse.data));
  yield put(CartActions.sendOrderApiSuccess(orderCheckoutResponse.data));
  yield setQuantityProducts(carrinho);
  yield localStorage.removeItem(`@ConexaoGAM:cart#${profile.uuid}`);
  yield put(
    MyOrderActions.setOrderSelected({
      id: orderCheckoutResponse.data.orders[0].id,
    })
  );

  if (
    orderCheckoutResponse.data.payment.tipoPagamento === "O" &&
    orderCheckoutResponse.data.payment.formaPagamento === "PI"
  )
    history.push("/checkout/pagamento/pendente");
  else history.push("/checkout/pedido/concluido");
}

//função de pedido chamada
export function* finishOrder({ payload }) {
  const { profile } = yield select(state => state.user);  
  const store = yield select(state => state.store.data);
  const { showOpeningHours } = yield select(state => state.order);
  const storeByUser = yield select(state => state.storeByUser.data);
  const checkoutContent = yield JSON.parse(
    localStorage.getItem("@ConexaoGAM:checkout")
  );
  const orderConfirmed = payload.orderConfirmed ?? false;

  try {
    if (
      storeByUser !== null &&
      !orderConfirmed &&
      checkoutContent.dadosEntrega.tipo_entrega === DeliveryType.RECEBER_EM_CASA
    ) {
      yield call(api.post, `/orders/check-store-delivery-city`, {
        lojaUuid: storeByUser !== null ? storeByUser.uuid : store.uuid,
        clienteUuid: profile.uuid,
        enderecoId: checkoutContent.address.id,
      });

      const deliveryTimeResponse = yield call(
        api.post,
        `/orders/check-store-delivery-time`,
        {
          lojaUuid: storeByUser !== null ? storeByUser.uuid : store.uuid,
          clienteUuid: profile.uuid,
          enderecoId: checkoutContent.address.id,
        }
      );

      if (deliveryTimeResponse.status === 202) {
        yield put(
          OrderActions.showConfirmOrderModal(deliveryTimeResponse.data)
        );
        return;
      }
    }

    if (
      checkoutContent.dadosEntrega.tipoEntrega === DeliveryType.RETIRAR_NA_LOJA
    ) {
      const checkStoreByUser = yield call(
        api.get,
        `/stores/${checkoutContent.dadosEntrega.lojaSelecionada.cnpj}/${checkoutContent.dadosEntrega.tipoEntrega}`
      );

      checkoutContent.dadosEntrega.lojaSelecionada = checkStoreByUser.data;
      checkoutContent.dadosEntrega.tipoEntrega = DeliveryType.RETIRAR_NA_LOJA;

      const currentDate = new Date();
      const startTimeStore = new Date(
        `${checkoutContent.dadosEntrega.lojaSelecionada.lojaHorario.data_disponibilidade} ${checkoutContent.dadosEntrega.lojaSelecionada.lojaHorario.hora_inicio}`
      );
      const endTimeStore = new Date(
        `${checkoutContent.dadosEntrega.lojaSelecionada.lojaHorario.data_disponibilidade} ${checkoutContent.dadosEntrega.lojaSelecionada.lojaHorario.hora_fim}`
      );
      const isOpenNow =
        currentDate > startTimeStore && currentDate < endTimeStore;

      if (
        checkoutContent.dadosEntrega.tipoEntrega ===
          DeliveryType.RETIRAR_NA_LOJA &&
        showOpeningHours === false &&
        !isOpenNow
      ) {
        yield put(
          OrderActions.showOpeningHoursModal(
            checkoutContent.dadosEntrega.lojaSelecionada
          )
        );
        return;
      }
      // }
    }

    yield completeOrder(payload);
  } catch (error) {
    console.log(error);
    window.scrollTo(0, 0);
    history.push("/checkout/pagamento");
    yield put(OrderActions.hideOpeningHoursModal());
    yield put(OrderActions.hideDeliveryStoreModal());

    switch (error.response.data.type) {
      case "ERROR_SELECTED_STORE_STOCK_ITEMS":
        yield put(
          OrderActions.orderError({
            visible: true,
            data: error.response.data,
            type: error.response.data.type,
          })
        );
        break;
      default:
        yield put(OrderActions.orderFailure(error.response.data.user));
        break;
    }
  }
}

export function* createPixPayment({ payload }) {
  try {
    let checkoutData = {
      orderId: payload.data,
    };

    const orderCheckoutResponse = yield call(
      api.post,
      `/orders/checkout-pix`,
      checkoutData
    );

    if (orderCheckoutResponse.status === 200) {
      yield put(
        OrderActions.orderRequestPixPaymentPending(orderCheckoutResponse.data)
      );
      return orderCheckoutResponse;
    }
  } catch(error) {
    console.log(error);
  } finally {
    yield put(OrderActions.setRefreshPage(true));
  }
}
