import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { formatPrice } from "../../util/format";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
    ItemsList,
    Item,
    ItemContainer,
    CollapseContainer,
    ItemContainerSecondary,
    Header,
    ContainerRow
} from "./styles";
import { DeliveryType } from '../../util/deliveryType';

const CollapseList = () => {
    const [collapsed, setIsCollapsed] = useState(true);

    const {
        data: basket,
    } = useSelector((state) => state.cart);

    const [productsWithAssociadaStock, setProductsWithAssociadaStock] = useState([]);
    const [productsWithoutAssociadaStock, setProductsWithoutAssociadaStock] = useState([]);
    const [timeContigency, setTimeContigency] = useState(0);
    const [deliveryType, setDeliveryType] = useState("");
    const [priceDelivery, setPriceDelivery] = useState(undefined);


    useEffect(() => {
        const infoCheckout = loadInfoCheckout();
        loadInfoStore(infoCheckout);
        loadProductsFromBasket(basket, infoCheckout);
    }, [basket]);

    function handleCollapse() {
        setIsCollapsed(!collapsed);
    }

    const loadInfoStore = (infoCheckout) => {
        if (infoCheckout) {
            setTimeContigency(infoCheckout.lojaSelecionada.tempo_contingencia_gam);
            setDeliveryType(infoCheckout.tipoEntrega);
            if (infoCheckout.tipoEntrega == DeliveryType.RECEBER_EM_CASA && infoCheckout.lojaSelecionada.custo_entrega > 0) {
                setPriceDelivery(infoCheckout.lojaSelecionada.custo_entrega);
            }
        }
    };

    const loadInfoCheckout = () => {
        const infoCheckout = localStorage.getItem("@ConexaoGAM:checkout");
        if (infoCheckout) {
            const { dadosEntrega } = JSON.parse(infoCheckout);
            return dadosEntrega;
        }
    };

    const getNextTimeStoreOpened = (infoCheckout) => {
        if (infoCheckout) {
            const initTime = infoCheckout.lojaSelecionada.lojaHorario?.hora_inicio;
            const endTime = infoCheckout.lojaSelecionada.lojaHorario?.hora_fim;
            const storeOpeningDate = infoCheckout.lojaSelecionada.lojaHorario?.data_disponibilidade;
            return `${storeOpeningDate} ${initTime.split(':').slice(0, 2).join(':')}-${endTime.split(':').slice(0, 2).join(':')}`;
        }
    }

    const transformStringToDate = (storeOpened) => {
        var [dayWeek, time] = storeOpened.split(" ")

        const dayWeekParameter = dayWeek.toLowerCase();
        var timeToAdd = 0;
        var currentDate = new Date();
        if (dayWeekParameter === "amanhã") {
            timeToAdd = 1;
        }
        else if (dayWeekParameter !== "hoje" && dayWeekParameter !== "amanhã") {
            var numberDayWeeek = {
                "domingo": 0,
                "segunda-feira": 1,
                "terça-feira": 2,
                "quarta-feira": 3,
                "quinta-feira": 4,
                "sexta-feira": 5,
                "sábado": 6
            }[dayWeekParameter];

            var currentDayWeek = currentDate.getDay();

            timeToAdd = ((numberDayWeeek) - currentDayWeek + 7) % 7;
        }

        currentDate.setDate(currentDate.getDate() + timeToAdd);

        var [hours, minutes] = time.split(':');
        currentDate.setHours(hours, minutes, 0, 0);
        return currentDate;
    }

    const loadProductsFromBasket = (basket, infoCheckout) => {
        if (basket && basket.length > 0) {
            let productsWithStock = [];
            let productsWithoutStock = [];

            const nextTimeStoreOpened = getNextTimeStoreOpened(infoCheckout);
            let [date, timeRange] = nextTimeStoreOpened.split(' ');
            let [, endTime] = timeRange.split('-');
            const endTimeBeforeClose = new Date(`${date} ${endTime}`);

            const maxWaitingDate = new Date();
            maxWaitingDate.setHours(maxWaitingDate.getHours() + infoCheckout.lojaSelecionada.tempo_contingencia_gam);
            const canWaitStore = maxWaitingDate <= endTimeBeforeClose;

            const receiveInDifferentsDays = infoCheckout.receberEmDiasDiferentes;

            basket.forEach((product) => {
                if (product.estoques && product.estoques.length > 0) {
                    const stock = product.estoques.find(e => e.loja_id = infoCheckout.lojaSelecionada.id)
                    var quantityNeededInStoreStock = stock.quantidade_associada > 0 ? (product.quantidade > stock.quantidade_associada ? stock.quantidade_associada : product.quantidade) : 0;
                    const quantityMissing = product.quantidade - quantityNeededInStoreStock;
                    var quantityNeededInGamStock = stock.quantidade_gam > 0 ? (quantityMissing > stock.quantidade_gam ? stock.quantidade_gam : quantityMissing) : 0;

                    if (canWaitStore) {
                        quantityNeededInStoreStock += quantityNeededInGamStock;
                        quantityNeededInGamStock = 0;
                    } else if (!canWaitStore && !receiveInDifferentsDays && quantityNeededInGamStock > 0) {
                        quantityNeededInGamStock += quantityNeededInStoreStock;
                        quantityNeededInStoreStock = 0;
                    }

                    if (quantityNeededInStoreStock > 0) {
                        productsWithStock.push({ ...product, quantidade: quantityNeededInStoreStock });
                    }
                    if (quantityNeededInGamStock > 0) {
                        productsWithoutStock.push({ ...product, quantidade: quantityNeededInGamStock });
                    }
                }
            });

            if (productsWithStock.length > 0) {
                setProductsWithAssociadaStock(productsWithStock);
            }

            if (productsWithoutStock.length > 0) {
                setProductsWithoutAssociadaStock(productsWithoutStock);
            }
        }
    };

    const ItemTemplate = (item) => {
        return (
            <Item>
                <div className="content-area">
                    <img
                        src={item.imagens[0].caminho_imagem}
                        alt={item.nome}
                    />
                    <div className="text-area">
                        <strong>{item.nome}</strong>
                        {(item.preco_mostrar_cesta.preco_de !== item.preco_mostrar_cesta.preco_por) && (
                            <>
                                <span className="oldValue">De: {formatPrice(item.preco_mostrar_cesta?.preco_de)}</span>
                                <span className="value">Por: {formatPrice(item.preco_mostrar_cesta?.preco_por)}</span>
                            </>
                        )}
                        {(item.preco_mostrar_cesta.preco_de === item.preco_mostrar_cesta.preco_por) && (
                            <div className="row">
                                <span className="value">{formatPrice(item.preco_mostrar_cesta?.preco_por)}</span>
                                <span className="quantity">{item.quantidade} {item.quantidade > 1 ? "itens" : "item"}</span>
                            </div>
                        )}
                    </div>
                </div>
            </Item>
        );
    }


    return (

        <ItemsList collapsed={collapsed} onClick={handleCollapse} style={{ cursor: "pointer" }}>
            <div>
                <Header style={{ paddingBottom: collapsed ? "0" : "20px" }}>
                    Meus produtos
                    <IoIosArrowDown style={{ display: collapsed ? "block" : "none", float: "right" }} />
                    <IoIosArrowUp style={{ display: collapsed ? "none" : "block", float: "right" }} />
                </Header>
                {!collapsed && (
                    <CollapseContainer>
                        {productsWithAssociadaStock.length > 0 && (
                            <ItemContainer>
                                <ContainerRow>
                                    <h3>{deliveryType == DeliveryType.RECEBER_EM_CASA ? "Entrega" : "Retirada"} rápida</h3>
                                    {deliveryType == DeliveryType.RECEBER_EM_CASA && priceDelivery && (<p>Taxa de entrega {formatPrice(priceDelivery)}</p>)}
                                </ContainerRow>
                                <>
                                    {productsWithAssociadaStock.map((item) => (
                                        <ItemTemplate key={item.id} {...item} />
                                    ))}
                                </>
                            </ItemContainer>
                        )}
                        {productsWithoutAssociadaStock.length > 0 && (
                            <ItemContainerSecondary>
                                <ContainerRow>
                                    <h3>{deliveryType == DeliveryType.RECEBER_EM_CASA ? "Entrega" : "Retirada"} em {timeContigency}h</h3>
                                    {deliveryType == DeliveryType.RECEBER_EM_CASA && priceDelivery && (<p>Taxa de entrega {formatPrice(priceDelivery)}</p>)}
                                </ContainerRow>

                                <>
                                    {productsWithoutAssociadaStock.map((item) => (
                                        <ItemTemplate key={item.id} {...item} />
                                    ))}
                                </>
                            </ItemContainerSecondary>
                        )}
                    </CollapseContainer>
                )}
            </div>
        </ItemsList>
    );
};

export default CollapseList;