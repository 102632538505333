import { darken } from "polished";
import styled from "styled-components";

export const UserData = styled.div`
  width: 100%;
  flex-direction: column;
  font-size: 1.6rem;
  display: flex;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 80px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  .account-avatar {
    display: flex;
    align-items: center;
    flex-direction: column;

    .account-avatar-data {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        color: ${props => props.theme.colors.neutral900};
        margin-top: 10px;
      }

      button {
        margin-top: 15px;
        width: 100%;
      }

      .userTitle{
        font-size: 25px;
        font-weight: 700;
      }

      .userSubtitle{
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .terms {
    margin-top: 24px;
    text-align: right;

    button {
      background: transparent;
      border: none;
      color: ${props => props.theme.colors.primary};
      font-weight: 500;
      text-decoration: none;
    }
  }

  .account-card-info {
    display: flex;
    margin: 20px 0;
    width: 100%;
    justify-content: space-between;

>span {
  line-height: 50px;
}

    span + span {
      text-transform: none;
      color: ${props => darken(0.1, props.theme.colors.primary)};
      font-weight: 500;
      display: flex;
      align-items: center;
    }

    a {
      background: none;
      border: none;
      line-height: 50px;
      svg {
        color: ${props => darken(0.1, props.theme.colors.primary)};
        font-size: 2.5rem;
        margin-left: 10px;
      }
    }
  }

  .account-actions {
    margin-top: 24px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 20px;

    @media (max-width: 768px) {
      align-items: center;
      justify-content: center;
    }

    button {
      display: flex;
      align-items: center;

      img {
        font-size: 2.5rem;
        margin-right: 10px;
      }
    }

    button {
      background: none;
      border: none;
      margin-top: 10px;
      padding: 10px 0;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      color: ${props => props.theme.colors.error}

      svg {
        font-size: 2.3rem;
        margin-right: 10px;
        margin-top: 1px;
      }
    }
  }
`;

export const AccessButton = styled.button`
  font-weight: 700;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  width: 93px;
  background: none;
  color: ${(props) => props.theme.colors.primary500};

  font-size: 16px;
  border-radius: 40px;
  font-family: 'Red Hat Display', sans-serif;;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.colors.primary500};

  >.btnDados {
    height: 50px;
    line-height: 50px;
  }

  &:hover {
    opacity: 0.9;
  }

  :disabled {
    opacity: 0.8;
  }

  > div {
    margin-top: 5px;
    margin-right: 12px;
  }

  span {
    font-weight: 500;
    margin-left: 5px;
  }

  @media (max-width: 900px) {
    max-width: 100%;
    padding: 0 10px;
  }
`;

export const ButtonFooterWrap = styled.button`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 218px;
  background: none;
  color: ${(props) => props.theme.colors.bodyDark};
  background: ${(props) => props.theme.colors.primary};
  font-size: 1.6rem;
  border-radius: 40px;
  font-family: 'Red Hat Display', sans-serif;;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid #cecece78;

  >.btnDados {
    height: 50px;
    line-height: 50px;
  }

  &:hover {
    opacity: 0.9;
  }

  :disabled {
    opacity: 0.8;
  }

  > div {
    margin-top: 5px;
    margin-right: 12px;
  }

  span {
    font-weight: 500;
    margin-left: 5px;
  }

  @media (max-width: 900px) {
    max-width: 100%;
    padding: 0 10px;
  }
`;

export const FormContainer = styled.form`
  width: 100%;
  max-width: 800px;

  .groupLine {
    display: flex;
    width: 100%;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  >.smallField {
    width: 30%;
  }

  .marginLeft{
    margin-left: 20px;
    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }

  .halfWidth{
    width: 48.2%;
    align-self: flex-start;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .btnArea{
    width: 100%;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
      button[type="submit"]{
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {

  }
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
`;

export const FieldErrorMessage = styled.div`
  color: ${props => props.theme.colors.warning};
  font-size: 14px;
  margin-top: 5px;
  font-family: 'Red Hat Display', sans-serif;;
`;

export const InputField = styled.input`
    height: 45px;
    background: #ffffff;
    border: 1px solid
      ${props => (props.invalid ? props.theme.colors.warning : "#cfcdcd")};
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    transition: all 0.3s ease;
    -webkit-appearance: none;

    + label {
      font-size: 12px;
      font-weight: 700;
      color: ${props =>
    props.invalid
      ? props.theme.colors.warning
      : props.theme.colors.neutral900};
    }

    :focus {
      border: 1px solid
        ${props =>
    props.invalid
      ? props.theme.colors.warning
      : props.theme.colors.primary};
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    :focus + label {
      color: ${props =>
    props.invalid ? "red" : props => props.theme.colors.primary};
    }

    ::placeholder {
      color: ${props => props.theme.colors.bodyLight};
    }

    :disabled {
      background: #f1f1f1;
    }
`;

export const Label = styled.label`
  transition: all 0.3s ease;
  font-family: 'Red Hat Display', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const RadioGroup = styled.div`
  display: flex;
  font-size: 1.6rem;

  .radio {
    margin: 0.5rem;
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        &:before {
          content: "";
          border-radius: 100%;
          border: 1px solid ${props => props.theme.colors.neutral400};
          display: inline-block;
          width: 1.4em;
          height: 1.4em;
          position: relative;
          top: -0.2em;
          margin-right: 1em;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          color: ${props => props.theme.colors.primary};
          &:before {
            border: 1px solid ${props => props.theme.colors.primary};
            background-color: ${props => props.theme.colors.primary};
            box-shadow: inset 0 0 0 4px #f4f4f4;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: ${props => props.theme.colors.primary};
          }
        }
      }
      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px #f4f4f4;
            border-color: ${darken(0.25, "#f4f4f4")};
            background: ${darken(0.25, "#f4f4f4")};
          }
        }
      }
      &:hover {
        + .radio-label {
          color: ${props => props.theme.colors.primary};
          &:before {
            border-color: ${props => props.theme.colors.primary};
          }
        }
      }
      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    flex-wrap: wrap;
    .naoInformar{
      width: 100%;
    }
  }
`;

export const SubmitButton = styled.button`
  padding: 16px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background: none;
  color: ${props => props.theme.colors.bodyDark};
  background: ${props => props.theme.colors.primary};
  font-size: 16px;
  font-weight: 700;
  border-radius: 40px;
  font-family: 'Red Hat Display', sans-serif;;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  order: 2;

  &:hover {
    opacity: 0.9;
  }

  span {
    font-weight: 500;
    margin-left: 5px;
  }

  :disabled {
    background: ${props => props.theme.colors.neutral200};
    color: ${props => props.theme.colors.neutral800};
  }

  @media (max-width: 760px) {
    margin-top: 0;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DataContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 80px;
  padding: 50px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  align-items: center;

  h1 {
    color: ${props => props.theme.colors.neutral900};
    font-weight: 700;
    font-size: 20px;
    font-family: 'Red Hat Display', sans-serif;
    padding-bottom: 40px;
    padding-top: 25px;
  }

  >div{
    width: 600px;
    background: #FFF;
    padding: 30px 30px 45px 30px;
    border-radius: 16px;

    @media (max-width: 768px) {
      position: fixed;
      top: 0;
      left: 0;
      margin: 0;
      width: 100%;
      height: 100vh;
      overflow-y: auto;
    }

    >div {
      >img {
        float: right;
        cursor: pointer;
      }
    }
  }
`

export const CancelBtn = styled.button`
  padding: 16px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background: none;
  color: ${props => props.theme.colors.primary500};
  font-size: 16px;
  font-weight: 700;
  border-radius: 40px;
  font-family: 'Red Hat Display', sans-serif;;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  order: 2;

  &:hover {
    opacity: 0.9;
  }

  span {
    font-weight: 500;
    margin-left: 5px;
  }

  :disabled {
    background: ${props => props.theme.colors.neutral200};
    color: ${props => props.theme.colors.neutral800};
  }

  @media (max-width: 760px) {
    margin-top: 0;
  }
`
