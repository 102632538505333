import { MdShoppingBasket } from "react-icons/md";
import styled from "styled-components";

import { darken, shade } from "polished";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import ButtonPrimary from "../../components/ButtonPrimary";
import { device } from "../../styles/device";
import ButtonSecundary from "../../components/ButtonSecundary";

export const ContainerPageCart = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  /* min-height: 100vh; */
  .produtcs-and-offers{
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: stretch;
    min-height: calc(100vh - 288px);
  }
  .footer{
    border-top: solid 1px #E8E8E9
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  max-width: 1440px;
  width: 100%;
  overflow: hidden;
  margin: 32px auto 0 auto;
  align-items: flex-start;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  @media (max-width: 1440px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const Wrap = styled.div`
  flex-direction: column;
  width: 100%;
  padding-right: 20px;
  border-right: solid 1px ${props => props.theme.colors.neutral100};
  @media (max-width: 900px) {
    padding-right: 0px;
     border-right:none;
  }
`;

export const ShoppingIcon = styled(MdShoppingBasket)``;

export const NoRewardsProgramMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background: #ffefbc;
  border-radius: 3px;
  flex-wrap: wrap;
  border: 1px solid ${shade(0.1, "#ffefbc")};
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  margin-top: 32px;
  position: relative;

  span {
    color: ${shade(0.5, "#ffefbc")};
    font-weight: 500;
    word-wrap: break-word;
    line-height: 20px;
    padding: 7px 5px;
    font-size: 1.6rem;
  }

  button {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5px;
    border: none;
    background: none;

    svg {
      color: ${shade(0.5, "#ffefbc")};
      font-size: 2rem;
    }
  }

  a {
    text-decoration: underline;
    font-weight: 600;
  }

  @media (max-width: 900px) {
    width: 90%;
    margin-bottom: 0;

    span {
      font-size: 1.4rem;
    }
  }

  @media (max-width: 660px) {
    margin-bottom: 16px;
  }
`;

export const ButtonFooterWrap = styled.button`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
  background: none;
  color: ${props => props.theme.colors.bodyDark};
  background: ${props => props.theme.colors.primary};
  font-size: 1.6rem;
  border-radius: 40px;
  font-family: 'Red Hat Display', sans-serif;;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid #cecece78;

  &:disabled{
    background: var(--neutral-200, #D0D1D3);
    color: var(--neutral-800, #44474E);
    cursor: not-allowed;
  }

  &:hover {
    opacity: 0.9;
  }

  span {
    font-weight: 500;
    margin-left: 5px;
  }

  @media (max-width: 900px) {
    /* max-width: 90%; */
    padding: 0 10px;
  }
`;

export const StyledButtonSecundary = styled(ButtonSecundary)`
  width: 100%;
  margin-top: 15px;
`;

export const IconArrowRight = styled(IoIosArrowForward)`
  margin-left: 10px;
`;

export const EmptyCartWrap = styled.div`
  max-width: 486px;
  margin-top: 140px;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  min-height: calc(100vh - 374px);

  h1 {
    margin-top: 20px;
    font-family: 'Red Hat Display', sans-serif;;
    font-weight: 300;
    color: ${props => props.theme.colors.bodyLight};
    text-align: center;
    font-size: 2.5rem;
    width: 100%;
  }
`;

export const KeepBuying = styled(Link)`
  font-size: 16px;
  padding: 10px 25px;
  text-decoration: none;
  margin-top: 15px;
  color: ${props => props.theme.colors.bodyLight};
  border: 1px solid ${props => props.theme.colors.bodyLight};
  font-family: 'Red Hat Display', sans-serif;;
  border-radius: 20px;
  transition: 0.2s;
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
  }
  &:hover {
    opacity: 0.8;
    box-shadow: 0px 1px 3px
      ${props => darken(0.03, props.theme.colors.bodyLight)};
  }
`;

export const PriceInfoWrap = styled.div`
  padding-left: 20px;
  display: flex;
  width: 100%;
  max-width: 500px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    width: 100%;
    max-width: 100%;
    margin: 10px 0 20px 0;
    padding-left: 0px;

  }

  .linkCompra {
    font-size: 14px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
    padding: 20px;
    cursor: pointer;
  }
`;

export const PriceInfoContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  :has(>.total) {
    border-top: solid 1px ${props => props.theme.colors.neutral100};
  }

  @media (max-width: 900px) {
    max-width: 100%;
    padding: 0px;
  }

  span.price-value {
    padding: 20px 25px;
    font-family: 'Red Hat Display', sans-serif;;
    font-size: 16px;
    color: ${props => props.theme.colors.black};
    font-weight: 700;
  }

  span.price-description {
    padding: 20px 50px 20px 0;
    font-family: 'Red Hat Display', sans-serif;;
    color: ${props => props.theme.colors.neutral900};
    font-size: 16px;
    font-weight: 500;
  }

  span.total {
    font-weight: 700;
    font-size: 20px;
  }
`;

export const OrderAgainButton = styled(Link)`
  max-width: 245px;
  width: 100%;
  height: 40px;
  border: none;
  color: ${props => props.theme.colors.primary};
  display: flex;
  margin: 20px 0 0;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  text-decoration: none;
  font-weight: 400;
  border: ${props => props.theme.colors.primary} 1px solid;
  border-radius: 3px;
  transition: background 200ms ease-in-out;

  :hover {
    color: ${props => props.theme.colors.bodyDark};
    background: ${props => darken(0.05, props.theme.colors.primary)};
  }

  @media (max-width: 660px) {
    margin: 5px 0 0 0;
  }
`;

export const TitleWrap = styled.div`
  margin-bottom: 30px;
  color: ${props => props.theme.colors.black};
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  align-self: start;
`;

export const EmptyCartImage = styled.img`
  margin-bottom: 43px;
`;

export const EmptyCartMessageContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: ${props => props.theme.colors.black};
    font-size: 16px;
    line-height: 26.5px;
    text-align: center;
  }
`;

export const GoBackButton = styled(ButtonPrimary)`
  margin-top: 40px;
  width: 100%;
  margin-bottom: 40px;
  @media ${device.tablet} {
    max-width: 204px;
  }
`;

export const KeepBuyingLink = styled(Link)`
  margin-top: 24px;
  color: ${props => props.theme.colors.primary};
`;
