import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import Banner from "../../components/Banner";
import FavIco from "../../components/FavIco";
import Categories from "../../components/Categories";
import CategoryFeatured from "../../components/CategoryFeatured";
import HelmetCustom from "../../components/HelmetCustom";
import ItemList from "../../components/ItemList";
import MainLayout from "../../components/MainLayout";
import { setTitle } from "../../services/browser";
import { Creators as ProductsActions } from "../../store/ducks/products";
import { Container } from "./styles";
import Loading from "../../components/Loading";
import api from '../../services/api';

export default function Main({ history }) {
  const dispatch = useDispatch();
  const { data: products, loading, hasMore } = useSelector(
    state => state.products
  );
  const { data } = useSelector(state => state.store);
  const [categoriesWithHighlightImage, setCategoriesWithHighlightImage] = useState([]);

  useEffect(() => {
    getCategoriesWithHighlightImage();
  }, []);

  useEffect(() => {
    setTitle(null, true);
    dispatch(ProductsActions.getProductsRequest());
  }, [dispatch]);


  function loadMore() {
    dispatch(ProductsActions.getProductsRequest());
  }

  const getCategoriesWithHighlightImage = async () => {
    try {
      const response = await api.get('categories/highlight-images')
      if (response.status === 200) {
        setCategoriesWithHighlightImage(response.data)
      } else {
        setCategoriesWithHighlightImage([])
      }
    } catch (err) {
      console.log(err)
      setCategoriesWithHighlightImage([])
    }
  }



  return (
    <>
      <FavIco />
      {data && data.empresa ? (
        <MainLayout history={history}>
          <Container>
            <HelmetCustom />
            <Banner />
            <Categories />
            <div className="products">
              {products.length >= 1 && (
                <ItemList
                  products={products}
                  hasMore={hasMore}
                  loadMore={loadMore}
                  loading={loading}
                />
              )}
            </div>
            {categoriesWithHighlightImage && categoriesWithHighlightImage.map((highlight) => (
              <CategoryFeatured key={highlight.id} category={
                {
                  id: highlight.id,
                  type: highlight.tipo,
                  description: highlight.nome,
                  image1: highlight.base64Imagem,
                  image2: highlight.base64Imagem2
                }
              } />
            ))}
          </Container>
        </MainLayout>
      ) : <Loading />}
    </>
  );
}
